<template>
  <v-text-field color="primary" density="compact" variant="outlined" v-bind="$attrs" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfTextField',
  inheritAttrs: false
})
</script>
