<template>
  <sf-text-field
    v-model="amount"
    class="field"
    hide-details
    single-line
    :disabled="disabled"
    :append-inner-icon="mdiPlus"
    :prepend-inner-icon="mdiMinus"
    :error="error"
    autocomplete="off"
    @click:prepend-inner="decrement()"
    @click:append-inner="increment()"
    @change="handleInput()"
    @blur="onBlur"
    @focus="[$event.target.select(), $emit('focus'), (blur = false)]"
    @keydown.enter="$event.target.blur()"
  />
</template>

<script lang="ts">
import SfTextField from '@/components/input/SfTextField.vue'
import helpers from '@/modules/message/helpers'
import { PackagingModel } from '@/generatedTypes'
import { mdiMinus, mdiPlus } from '@mdi/js'
import { computed, defineComponent, PropType, ref, watch } from 'vue'

export default defineComponent({
  name: 'QuantityStepper',
  components: { SfTextField },
  props: {
    quantity: {
      type: Number,
      required: true
    },
    stock: {
      type: Number,
      default: 0
    },
    packaging: {
      type: Object as PropType<PackagingModel | undefined>,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changeQuantity', 'blur', 'focus'],
  setup(props, { emit }) {
    const blur = ref(true)
    const timerStarted = ref(false)

    const minQuantity = computed(() => {
      if (props.packaging) {
        if (props.packaging.minQuantity > 1) {
          return props.packaging.minQuantity
        } else if (props.packaging.intervalQuantity > 1) {
          return props.packaging.intervalQuantity
        } else {
          return 1
        }
      } else {
        return 1
      }
    })
    const interval = computed(() => {
      if (props.packaging) {
        if (props.packaging.intervalQuantity > 1) {
          return props.packaging.intervalQuantity
        } else {
          return 1
        }
      } else {
        return 1
      }
    })
    const amount = ref(props.quantity >= minQuantity.value ? props.quantity : minQuantity.value)
    const lastValid = ref(amount.value)
    const error = computed(() => props.stock != 0 && props.quantity > props.stock)
    watch(
      () => props.quantity,
      (selection) => {
        amount.value = selection
        lastValid.value = selection
      }
    )
    const isValidQuantity = (quantity: number) => {
      if (quantity < minQuantity.value) {
        return false
      }
      if (quantity % interval.value != 0) {
        return false
      }
      return true
    }

    const handleInput = () => {
      const quantity = Number(amount.value)
      if (!Number.isNaN(quantity)) {
        if (quantity >= 1) {
          if (isValidQuantity(quantity)) {
            setQuantity(quantity)
          } else {
            amount.value = lastValid.value
          }
        } else {
          helpers.reportError('error.positiveDigit')
          amount.value = lastValid.value
        }
      } else {
        helpers.reportError('error.validDigit')
        amount.value = lastValid.value
      }
    }

    const increment = () => {
      setQuantity((amount.value += interval.value))
    }

    const decrement = () => {
      setQuantity(
        amount.value <= minQuantity.value ? minQuantity.value : (amount.value -= interval.value)
      )
    }

    const onBlur = () => {
      blur.value = true
      if (!timerStarted.value) {
        emit('blur')
      }
    }

    // Debounce the quantity changes before emitting
    const debounceTime = 1000
    let timeoutId: ReturnType<typeof setTimeout> | null = null

    const setQuantity = (quantity: number) => {
      if (lastValid.value != quantity) {
        lastValid.value = quantity
        if (timeoutId) clearTimeout(timeoutId)
        timerStarted.value = true
        timeoutId = setTimeout(() => {
          emit('changeQuantity', quantity)
          if (blur.value) {
            emit('blur')
          }
          timerStarted.value = false
        }, debounceTime)
      }
    }

    return {
      amount,
      lastValid,
      error,
      blur,
      increment,
      decrement,
      handleInput,
      onBlur,
      mdiPlus,
      mdiMinus
    }
  }
})
</script>

<style lang="scss" scoped>
.field {
  width: 115px;
  max-width: 115px;

  &:deep(input) {
    text-align: center;
  }
}
</style>
