<template>
  <v-row>
    <!-- Categories -->
    <v-col v-if="!$vuetify.display.mobile && categoriesShown" lg="3" xl="2">
      <product-categories
        :show-hide-button="currentRoute.name !== 'StartPage'"
        @update="selectCategory"
      />
    </v-col>
    <!-- Content -->
    <v-col cols="12" :lg="categoriesShown ? 9 : 12" :xl="categoriesShown ? 10 : 12">
      <error-component
        v-if="mustHandleError"
        :message="error.message"
        :code="error.code"
        :transparent-background="true"
      />
      <router-view v-else />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import ProductCategories from '@/modules/categories/components/ProductCategories.vue'
import useCategories from '@/modules/categories/useCategories'
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { customErrorHandling } from '@/modules/error/helpers'
import { ProductSort } from '@/modules/product/types'
import useProductFilter from '@/modules/product/useProductFilter'
import { StartPageType } from '@/generatedTypes'
import useStartPage from '@/modules/startPage/useStartPage'
import router from '@/router'
import { storeToRefs } from 'pinia'
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'CategoryLayout',
  components: { ProductCategories, ErrorComponent },
  setup() {
    const currentRoute = useRoute()
    if (!currentRoute.path.startsWith('/products/category')) {
      useCategories().loadCategories()
    }

    const { error, mustHandleError } = customErrorHandling()

    const { startPage } = storeToRefs(useStartPage())
    const { showCategories } = storeToRefs(useCategories())

    const categoriesShown = computed(() => {
      if (currentRoute.name === 'StartPage') {
        return startPage.value.startPageType !== StartPageType.categories
      } else {
        return showCategories.value
      }
    })

    const selectCategory = (categorySearchTerm: string) => {
      useProductFilter().resetFilter(ProductSort.POPULARITY)
      useProductFilter().category = categorySearchTerm
      if (currentRoute.params.id !== categorySearchTerm) {
        router.push({
          name: 'Category',
          params: { id: categorySearchTerm }
        })
      }
    }

    return {
      currentRoute,
      StartPageType,
      startPage,
      categoriesShown,
      selectCategory,
      error,
      mustHandleError
    }
  }
})
</script>
