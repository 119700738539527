<template>
  <v-card
    :to="{ name: 'Product', params: { id: product.productId } }"
    class="d-flex flex-column pw-product-comparison-card"
  >
    <v-img
      class="flex-grow-0"
      aspect-ratio="1"
      :lazy-src="emptyPlaceholderImage"
      :src="product.imageUrls[0] ? fitImage(product.imageUrls[0], 520, 520) : emptyPlaceholderImage"
      :srcset="
        product.imageUrls[0] ? getSrcset(product.imageUrls[0], 520, 520) : emptyPlaceholderImage
      "
    />
    <v-icon
      class="d-block card-button-position mr-2 mt-2"
      :icon="mdiClose"
      @click.prevent="removeProduct(product.productId)"
    />
    <v-card-text class="d-flex flex-column">
      <sf-text ref="title" class="two-lines text-justify mb-1">
        {{ product.title }}
      </sf-text>
      <div
        v-if="product.price && (product.price.subscriptionModel || product.price.packagingModel)"
        ref="price-addition"
        class="d-flex justify-end text-primary"
      >
        <billing-period
          v-if="product.price.subscriptionModel"
          class="text-body-1"
          :subscription-model="product.price.subscriptionModel"
        />
        <packaging-info
          v-if="product.price.packagingModel"
          class="text-body-1"
          :packaging-model="product.price.packagingModel"
        />
      </div>
      <div class="d-flex flex-row align-center">
        <cart-button
          is-icon
          :product-id="product.productId"
          :quantity="1"
          :supplier-row-id="supplierRowId()"
          :on-click-event="addToCartEvent"
          :is-request="isRequest(product)"
        />
        <v-spacer />
        <span v-if="product.price" ref="price" class="text-h5 text-primary">
          {{ formatMoney(product.price.resellPrice, true) }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import PackagingInfo from '@/components/PackagingInfo.vue'
import CartButton from '@/components/button/CartButton.vue'
import SfText from '@/components/text/SfText.vue'
import { emptyPlaceholderImage, fitImage, getSrcset } from '@/helpers'
import { formatMoney, getStatusColor } from '@/modules/product/helpers'
import { FullProduct } from '@/generatedTypes'
import { useProductComparison } from '@/modules/product/useProductComparison'
import ProductComparisonEvent from '@/modules/tracking/events/productComparison'
import { trackEvent } from '@/modules/tracking/useTracking'
import { mdiClose } from '@mdi/js'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductComparisonCard',
  components: {
    CartButton,
    SfText,
    BillingPeriod,
    PackagingInfo
  },
  props: {
    product: {
      type: Object as PropType<FullProduct>,
      required: true
    }
  },
  setup(props) {
    const supplierRowId = () => props.product.price?.supplierRowId || undefined
    const removeProduct = (id: number) => {
      trackEvent(ProductComparisonEvent.RemoveProduct.withProduct(id))
      useProductComparison().removeProduct(id)
      useProductComparison().loadProducts()
    }
    const isRequest = (product: FullProduct) =>
      product.price?.resellPrice && product.price.resellPrice.value <= 0

    return {
      getStatusColor,
      formatMoney,
      supplierRowId,
      fitImage,
      getSrcset,
      removeProduct,
      isRequest,
      addToCartEvent: ProductComparisonEvent.AddToCart,
      emptyPlaceholderImage,
      mdiClose
    }
  }
})
</script>
