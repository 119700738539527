<template>
  <v-checkbox
    v-model="checked"
    color="primary"
    :rules="[rules.requiredRule]"
    hide-details
    @update:model-value="$emit('input', checked)"
  >
    <template #label>
      <span @click.stop v-html="customTerms" />
    </template>
  </v-checkbox>
</template>

<script lang="ts">
import rules from '@/rules'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'CustomTermsCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    customTerms: {
      type: String,
      required: true
    }
  },
  emits: ['input', 'update:modelValue'],
  setup(props) {
    const checked = ref(props.modelValue)

    return {
      checked,
      rules
    }
  }
})
</script>
