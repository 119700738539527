<template>
  <v-btn
    id="cart-toolbar-button"
    :to="{ name: 'ShoppingCart' }"
    variant="text"
    rounded
    elevation="0"
    class="pa-0 text-medium-emphasis"
    height="32"
    @click="track"
  >
    <v-badge
      v-show="!loading"
      id="cart-toolbar-count"
      bordered
      :content="cartCount"
      :offset-x="-8"
      :offset-y="12"
      color="primary"
    >
      <v-icon size="28" :icon="mdiCart" />
    </v-badge>
    <v-badge v-show="loading" :offset-x="-6" :offset-y="12" color="transparent">
      <v-icon size="28" :icon="mdiCart" />
      <template #badge>
        <v-progress-circular indeterminate :size="15" color="primary" />
      </template>
    </v-badge>
  </v-btn>
</template>

<script lang="ts">
import useCartCount from '@/modules/cart/useCartCount'
import ApplicationHeaderEvent from '@/modules/tracking/events/applicationHeader'
import { trackEvent } from '@/modules/tracking/useTracking'
import { mdiCart } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ToolbarShoppingCart',
  setup() {
    useCartCount().loadCartCount()

    const { cartCount, loading } = storeToRefs(useCartCount())

    const track = () => {
      trackEvent(ApplicationHeaderEvent.Cart)
    }

    return {
      cartCount,
      loading,
      track,
      mdiCart
    }
  }
})
</script>

<style lang="scss" scoped>
.v-btn--active.v-btn.v-btn--rounded {
  color: rgb(var(--v-theme-primary)) !important;
}
</style>
