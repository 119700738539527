<template>
  <sf-button :loading="requesting" @click="sendRequest">
    {{ $t('quoteRequest.sendRequest') }}
  </sf-button>
</template>

<script lang="ts">
import SfButton from '@/components/button/SfButton.vue'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import QuoteEvent from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RequestQuoteActionButton',
  components: { SfButton },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup() {
    const { requesting } = storeToRefs(useQuoteRequest())

    const sendRequest = () => {
      useQuoteRequest().sendRequest()
      trackEvent(QuoteEvent.SendRequest)
    }

    return {
      requesting,
      sendRequest
    }
  }
})
</script>
