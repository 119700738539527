<template>
  <v-card height="100%">
    <v-card-item>
      <v-card-title class="d-flex">
        <sf-heading>{{ $t('order.moreInfosLabel') }}</sf-heading>
      </v-card-title>
    </v-card-item>
    <v-card-text class="d-flex flex-column ga-1">
      <sf-debounced-textarea
        v-if="editable"
        v-model="orderCommentValue"
        :label="$t('common.comment')"
        :placeholder="$t('common.commentPlaceholder')"
        rows="1"
        auto-grow
        hide-details="auto"
        density="compact"
        variant="underlined"
        autocomplete="off"
        :maxlength="maxLength"
        :hint="orderCommentValue.length >= maxLength ? $t('maxLength', [maxLength]) : ''"
        persistent-hint
        @debounce="updateComment"
      />
      <template v-if="!editable && orderComment">
        {{ $t('common.comment') }}:
        <span class="font-italic text-pre-wrap">{{ orderComment }}</span>
      </template>
      <v-checkbox
        v-if="partialShipmentEnabled || (partialShipmentSelection && !editable)"
        v-model="partialShipmentSelection"
        color="primary"
        density="compact"
        :disabled="!editable"
        :label="$t('order.partialShipmentAllowed')"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import SfDebouncedTextarea from '@/components/input/SfDebouncedTextarea.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { CommentChangeEvents } from '@/modules/tracking/events/checkout'
import { trackEvent } from '@/modules/tracking/useTracking'
import { computed, defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'OrderComment',
  components: { SfHeading, SfDebouncedTextarea },
  props: {
    orderComment: {
      type: String,
      default: ''
    },
    partialShipmentSelect: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      required: true
    },
    partialShipmentEnabled: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      required: true
    },
    events: {
      type: Object as PropType<CommentChangeEvents>,
      default: undefined
    }
  },
  emits: ['orderCommentChanged', 'partialShipmentSelectionChanged'],
  setup(props, { emit }) {
    const orderCommentValue = ref(props.orderComment)

    const updateComment = () => {
      emit('orderCommentChanged', orderCommentValue.value)
      if (props.events) {
        trackEvent(props.events.CommentChanged)
      }
    }

    const partialShipmentSelection = computed({
      get(): boolean {
        return props.partialShipmentSelect
      },
      set(value: boolean) {
        emit('partialShipmentSelectionChanged', value)
        if (props.events) {
          trackEvent(props.events.PartialShipment(value))
        }
      }
    })

    return {
      orderCommentValue,
      partialShipmentSelection,
      updateComment
    }
  }
})
</script>
