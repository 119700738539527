<template>
  <sf-alert-base v-bind="{ icon: mdiAlertOutline, ...$attrs }" type="warning" color="deep-orange">
    <slot />
  </sf-alert-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SfAlertBase from '@/components/alerts/SfAlertBase.vue'
import { mdiAlertOutline } from '@mdi/js'

export default defineComponent({
  name: 'SfAlertWarning',
  components: { SfAlertBase },
  setup() {
    return {
      mdiAlertOutline
    }
  }
})
</script>
