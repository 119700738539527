<template>
  <v-app>
    <loading-spinner v-if="loading" />
    <template v-else>
      <server-offline-page v-if="requestManager.serverOffline || !branding" />
      <template v-else>
        <router-view />
        <messaging-center />
      </template>
    </template>
  </v-app>
</template>

<script setup lang="ts">
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import '@fontsource/heebo/100.css'
import '@fontsource/heebo/200.css'
import '@fontsource/heebo/300.css'
import '@fontsource/heebo/400.css'
import '@fontsource/heebo/500.css'
import '@fontsource/heebo/600.css'
import '@fontsource/heebo/700.css'
import '@fontsource/heebo/800.css'
import '@fontsource/heebo/900.css'
import '@fontsource/maven-pro/400.css'
import '@fontsource/maven-pro/500.css'
import '@fontsource/maven-pro/600.css'
import '@fontsource/maven-pro/700.css'
import '@fontsource/maven-pro/800.css'
import '@fontsource/maven-pro/900.css'
import useBranding from '@/modules/portalSettings/useBranding'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { onBeforeMount, onBeforeUnmount, watch } from 'vue'
import { storeToRefs } from 'pinia'
import useAuthentication from '@/modules/authentication/useAuthentication'
import MessagingCenter from '@/modules/message/components/MessagingCenter.vue'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import useRequestManager from '@/store/useRequestManager'
import ServerOfflinePage from '@/pages/ServerOfflinePage.vue'
import { fitImage } from '@/helpers'
import { useFavicon } from '@vueuse/core'

const { loading, branding } = useBranding()
const requestManager = useRequestManager().state
const { portalSettings } = storeToRefs(usePortalSettings())

onBeforeMount(() => {
  window.addEventListener('beforeunload', clearBrowserSession)
})

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', clearBrowserSession)
})

const clearBrowserSession = () => {
  if (portalSettings.value.clearBrowserSession) {
    useAuthentication().logout()
  }
}

watch(branding, () => {
  if (branding.value && branding.value.faviconUrl) {
    useFavicon(fitImage(branding.value.faviconUrl, 32, 32, '&'))
  }
})
</script>
