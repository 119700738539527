import { get, post, Response } from '@/api'
import { EmailStatus, Password } from '@/modules/user/types'
import {
  AuthTokens,
  ChangePasswordDTO,
  PasswordResetDTO,
  InvitationRegistration,
  SelfRegistration,
  Token,
  CustomTerms,
  ValidTokenDTO
} from '@/generatedTypes'

export default {
  getAccessToken: (refreshToken: string): Promise<Response<AuthTokens>> => {
    return get<AuthTokens>('/user/actions/access-token', {
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    })
  },
  getLoginRefreshToken: (basicAuthString: string): Promise<Response<string>> => {
    return get<string>('/user/actions/login', {
      headers: {
        Authorization: basicAuthString
      }
    })
  },
  resetPassword: (data: PasswordResetDTO): Promise<Response<string>> => {
    return post<string, PasswordResetDTO>('/user/actions/reset-password', data)
  },
  checkPasswordToken: (data: Token): Promise<Response<Token>> => {
    return post<Token, Token>('/user/actions/validate-password-token', data)
  },
  checkRegisterToken: (data: Token): Promise<Response<ValidTokenDTO>> => {
    return post<ValidTokenDTO, Token>('/registration/invitation/validate-token', data)
  },
  changePassword: (data: ChangePasswordDTO): Promise<Response<Token>> => {
    return post<Token, ChangePasswordDTO>('/user/actions/change-password', data)
  },
  validateRegistrationEmail: (email: string): Promise<Response<EmailStatus>> => {
    return post<EmailStatus, { email: string }>('/registration/validate-mail', {
      email
    })
  },
  getCustomTerms: (lang: string): Promise<Response<CustomTerms>> => {
    return get<CustomTerms>('/registration/customterms', {
      params: { lang }
    })
  },
  finishRegistration: (data: InvitationRegistration): Promise<Response<string>> => {
    return post<string, InvitationRegistration>('/registration/invitation', data)
  },
  guestRegistration: (data: Password): Promise<Response<string>> => {
    return post<string, Password>('/guest/registration', data)
  },
  executeSelfRegistration: (data: SelfRegistration): Promise<Response<string>> => {
    return post<string, SelfRegistration>('/registration', data)
  }
}
