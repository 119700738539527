<template>
  <v-row>
    <v-col cols="12">
      <list-header
        v-if="!loading && modelConfiguration"
        :title="$t('cto.configure', [modelConfiguration.baseModel.descriptionTwo])"
      />
      <template v-else>
        <v-skeleton-loader type="heading" />
        <v-divider class="my-2" />
      </template>

      <list-content>
        <v-col cols="12">
          <v-card class="pa-2">
            <!-- Loading -->
            <v-row v-if="loading">
              <v-col cols="12" md="4">
                <v-skeleton-loader class="mb-2" type="heading" />
                <div v-for="index in 4" :key="index">
                  <v-skeleton-loader type="heading" />
                  <v-skeleton-loader type="list-item" />
                  <v-skeleton-loader type="list-item" />
                </div>
              </v-col>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col v-for="index in 2" :key="index" cols="12">
                    <v-skeleton-loader type="heading" />
                    <v-skeleton-loader class="mt-2" type="image" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- Model configuration -->
            <v-row v-if="!loading && modelConfiguration">
              <v-col cols="12" md="4">
                <sf-heading>{{ $t('cto.customiseConfiguration') }}</sf-heading>
                <v-sheet class="mt-2">
                  <model-component-selection
                    v-for="modelComponentGroup in modelConfiguration.modelComponentGroups"
                    :key="modelComponentGroup.groupId"
                    :model-component-group="modelComponentGroup"
                    @select-model-component="selectModelComponent"
                  />
                </v-sheet>
              </v-col>

              <v-col cols="12" md="8">
                <v-row dense class="mb-1">
                  <v-col cols="12">
                    <sf-heading>{{ $t('cto.selectedConfiguration') }}</sf-heading>
                    <selected-model-configuration
                      :model-configuration="modelConfiguration"
                      :selected-model-components="selectedModelComponents"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <sf-heading>{{ $t('cto.foundProduct') }}</sf-heading>
                    <v-card
                      v-if="loadingCatalogProduct || (!loadingCatalogProduct && !catalogProduct)"
                      variant="outlined"
                      height="100px"
                      color="grey-lighten-2"
                      class="pa-2 mt-2 d-flex justify-center align-center"
                    >
                      <loading-spinner v-if="loadingCatalogProduct" height="50px" flat />
                      <sf-text v-if="!loadingCatalogProduct && !catalogProduct">{{
                        $t('cto.noProductFound')
                      }}</sf-text>
                    </v-card>
                    <catalog-product-card
                      v-if="!loadingCatalogProduct && catalogProduct"
                      class="mt-2"
                      :product="catalogProduct"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </list-content>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ListContent from '@/components/list/ListContent.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { fitImage, getSrcset, useTitle } from '@/helpers'
import CatalogProductCard from '@/modules/ctoFinder/components/CatalogProductCard.vue'
import ModelComponentSelection from '@/modules/ctoFinder/components/ModelComponentSelection.vue'
import SelectedModelConfiguration from '@/modules/ctoFinder/components/SelectedModelConfiguration.vue'
import useCtoFinder from '@/modules/ctoFinder/useCtoFinder'
import router from '@/router'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CtoModelConfigurationPage',
  components: {
    CatalogProductCard,
    ListContent,
    ListHeader,
    LoadingSpinner,
    ModelComponentSelection,
    SfHeading,
    SfText,
    SelectedModelConfiguration
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('cto.appleCtoFinder')}`))
    useCtoFinder().getModelConfiguration(router.currentRoute.value.params.id as string)

    const {
      modelConfiguration,
      catalogProduct,
      loading,
      loadingCatalogProduct,
      selectedModelComponents
    } = storeToRefs(useCtoFinder())

    const selectModelComponent = (modelComponentGroupId: number, modelComponentId: number) => {
      useCtoFinder().selectModelComponent(modelComponentGroupId, modelComponentId)
    }

    return {
      modelConfiguration,
      selectedModelComponents,
      catalogProduct,
      loadingCatalogProduct,
      loading,
      selectModelComponent,
      fitImage,
      getSrcset
    }
  }
})
</script>
