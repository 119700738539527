<template>
  <v-row>
    <v-col cols="12" :lg="isQuotesPortal && showActivity ? 9 : 12">
      <quote-loading v-if="loading" />

      <template v-if="!loading && quote">
        <div
          class="d-flex justify-space-between flex-column flex-lg-row"
          :style="!$vuetify.display.mobile ? 'height: 36px' : ''"
        >
          <div class="d-flex flex-row">
            <show-categories-button v-if="!isQuotesPortal && !isGuest" class="mr-1" />
            <sf-icon-button
              v-else-if="isLoggedInQuotesPortal"
              :icon="mdiArrowLeftBoldOutline"
              @click="navigateToQuotesOverview"
            />
            <div class="d-flex flex-wrap flex-lg-nowrap align-center">
              <editable-heading
                v-if="quote.quoteStatus === QuoteStatus.DRAFT"
                class="mr-2"
                :value="quote.name"
                @input="updateTitle"
              />
              <sf-title
                v-else
                class="mr-2"
                :class="[
                  {
                    'text-disabled': quote.quote && quote.quote.isArchived,
                    'text-truncate': !$vuetify.display.mobile
                  }
                ]"
              >
                {{ quote.name }}
              </sf-title>
              <div class="d-flex flex-shrink-0 align-center">
                <sf-color-text
                  id="pw-quote-status"
                  :color="getQuoteStatusColor(quote.quoteStatus)"
                  class="text-center"
                >
                  {{ $t('quoteStatus.' + quote.quoteStatus) }}
                </sf-color-text>
                <sf-archived-chip v-if="quote.quote && quote.quote.isArchived" class="ml-2" />
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap justify-end flex-shrink-0">
            <delete-quote-request-button
              v-if="quote.quoteStatus === QuoteStatus.DRAFT"
              :id="quote.id"
            />
            <request-quote-action-button v-if="showRequestButton" :id="quote.id" class="ml-1" />
            <template v-if="(isQuotesPortal || isGuest) && quote.quoteStatus !== QuoteStatus.DRAFT">
              <sf-text-button
                v-if="isGuest && quote.quoteStatus !== QuoteStatus.REQUESTED"
                ref="printButton"
                class="text-medium-emphasis"
                :loading="printing"
                :disabled="isReadonly"
                @click="printQuote"
              >
                {{ $t('print') }}
              </sf-text-button>
              <rejection-dialog
                v-if="quote.quoteStatus === QuoteStatus.NEGOTIATION"
                :guest-quote="true"
                :rejecting="rejecting"
                :type="'QUOTE'"
                :disabled="isReadonly"
                @reject="rejectQuote"
              />
              <sf-button
                v-if="quote.quoteStatus === QuoteStatus.NEGOTIATION"
                ref="ct-checkout-button"
                :disabled="
                  isReadonly ||
                  noPositionSelected ||
                  !addressValidation.valid ||
                  (quote.quote && quote.quote.validDate && !dateNotOver(quote.quote.validDate)) ||
                  isDuplicateUser
                "
                :loading="ordering"
                @click="orderQuote"
              >
                {{ $t('quotes.acceptQuote') }}
              </sf-button>
            </template>
            <div class="ml-1">
              <quote-menu
                v-if="
                  !isGuest &&
                  quote.quoteStatus !== QuoteStatus.REQUESTED &&
                  quote.quoteStatus !== QuoteStatus.DRAFT
                "
              />
            </div>
            <sf-icon-button
              v-if="
                (isQuotesPortal || isGuest) &&
                quote.quoteStatus !== QuoteStatus.DRAFT &&
                (!showActivity || $vuetify.display.mobile)
              "
              id="pw-quote-activity-toggle"
              class="ml-1"
              :size="32"
              :icon="mdiMessageText"
              @click="toggleActivity()"
            />
          </div>
        </div>
        <sf-alert-warning v-if="isDuplicateUser" class="mt-1">
          {{ $t('quotes.duplicateUserWarning') }}
        </sf-alert-warning>
        <sf-alert-warning
          v-if="quote.quote && quote.quote.validDate && !dateNotOver(quote.quote.validDate)"
          class="mt-1"
        >
          {{ $t('quotes.noLongerValid', [formatDate(quote.quote.validDate, false)]) }}
        </sf-alert-warning>
        <template v-if="quote.quote && !quoteRequest && !quote.orderId">
          <v-divider class="mt-2 mb-1" />
          <quote-details :quote="quote.quote" :quote-status="quote.quoteStatus" />
        </template>
        <template v-else>
          <v-tabs v-model="tab" class="mt-1" show-arrows bg-color="transparent" color="primary">
            <v-tab v-if="quoteRequest" :value="QuoteTabs.REQUEST">{{ $t('quotes.request') }}</v-tab>
            <v-tab :value="QuoteTabs.QUOTE">{{ $t('quotes.quote') }}</v-tab>
            <v-tab v-if="!isQuotesPortal && quote.orderId" :value="QuoteTabs.ORDER">
              {{ $t('order.order') }}
            </v-tab>
            <v-tab v-if="quote.orderId" :value="QuoteTabs.DOCUMENTS">
              {{ $t('order.documents') }}
            </v-tab>
          </v-tabs>
          <v-divider />
          <v-window v-model="tab">
            <v-window-item v-if="quoteRequest" class="mx-half" :value="QuoteTabs.REQUEST">
              <request-details />
            </v-window-item>
            <v-window-item class="mx-half" :value="QuoteTabs.QUOTE">
              <quote-details
                v-if="quote.quote"
                :quote="quote.quote"
                :quote-status="quote.quoteStatus"
              />
              <list-empty v-else :icon="mdiHandshake" :message="$t('quotes.noQuoteEmptySpace')" />
            </v-window-item>
            <v-window-item
              v-if="!isQuotesPortal && quote.orderId"
              class="mx-half"
              :value="QuoteTabs.ORDER"
            >
              <order-details />
            </v-window-item>
            <v-window-item v-if="quote.orderId" class="mx-half" :value="QuoteTabs.DOCUMENTS">
              <order-documents :order-id-prop="quote.orderId" />
            </v-window-item>
          </v-window>
        </template>
      </template>
    </v-col>
    <template v-if="!loading && quote && quote.quoteStatus !== QuoteStatus.DRAFT && isQuotesPortal">
      <mobile-side-menu
        v-if="$vuetify.display.mobile"
        :show="showActivity"
        @close="toggleActivity()"
      >
        <quote-activity :quote-id="quote.id" @close="toggleActivity()" />
      </mobile-side-menu>

      <v-col v-if="!$vuetify.display.mobile" v-show="showActivity" id="pw-quote-activity" cols="3">
        <quote-activity :quote-id="quote.id" @close="toggleActivity()" />
      </v-col>
    </template>
  </v-row>
</template>

<script lang="ts">
import MobileSideMenu from '@/components/MobileSideMenu.vue'
import RejectionDialog from '@/components/RejectionDialog.vue'
import ShowCategoriesButton from '@/components/ShowCategoriesButton.vue'
import SfAlertWarning from '@/components/alerts/SfAlertWarning.vue'
import SfButton from '@/components/button/SfButton.vue'
import SfIconButton from '@/components/button/SfIconButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfArchivedChip from '@/components/chips/SfArchivedChip.vue'
import EditableHeading from '@/components/input/EditableHeading.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfTitle from '@/components/text/SfTitle.vue'
import { QuoteStatus } from '@/generatedTypes'
import { isGuest, isQuotesPortal, scrollToTop, useTitle } from '@/helpers'
import { AddressType } from '@/modules/address/types'
import useAddressValidation from '@/modules/address/useAddressValidation'
import useAuthentication from '@/modules/authentication/useAuthentication'
import OrderDetails from '@/modules/order/components/OrderDetails.vue'
import OrderDocuments from '@/modules/order/components/OrderDocuments.vue'
import useOrder from '@/modules/order/useOrder'
import DeleteQuoteRequestButton from '@/modules/quote/components/DeleteQuoteRequestButton.vue'
import QuoteActivity from '@/modules/quote/components/QuoteActivity.vue'
import QuoteDetails from '@/modules/quote/components/QuoteDetails.vue'
import QuoteLoading from '@/modules/quote/components/QuoteLoading.vue'
import QuoteMenu from '@/modules/quote/components/QuoteMenu.vue'
import RequestDetails from '@/modules/quote/components/RequestDetails.vue'
import RequestQuoteActionButton from '@/modules/quote/components/RequestQuoteActionButton.vue'
import { getQuoteStatusColor } from '@/modules/quote/helpers'
import useQuote from '@/modules/quote/useQuote'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import QuoteEvent, { GuestQuoteEvent } from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import { QuoteTabs } from '@/types'
import { dateNotOver, formatDate } from '@/utils/timeFormatHelpers'
import { mdiArrowLeftBoldOutline, mdiHandshake, mdiMessageText } from '@mdi/js'
import { useToggle } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onBeforeUnmount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import useGuestUser from '@/modules/user/useGuestUser'

export default defineComponent({
  name: 'QuotePage',
  components: {
    DeleteQuoteRequestButton,
    EditableHeading,
    ListEmpty,
    MobileSideMenu,
    OrderDetails,
    OrderDocuments,
    QuoteActivity,
    QuoteDetails,
    QuoteLoading,
    QuoteMenu,
    RejectionDialog,
    RequestDetails,
    RequestQuoteActionButton,
    SfAlertWarning,
    SfArchivedChip,
    SfButton,
    SfColorText,
    SfIconButton,
    SfTextButton,
    SfTitle,
    ShowCategoriesButton
  },
  setup() {
    const currentRoute = useRoute()
    useQuote().getQuote(currentRoute.params.id as string)
    onBeforeUnmount(() => useQuote().closeQuoteUpdateStream())

    const { quote, loading, rejecting, printing, ordering, archiving } = storeToRefs(useQuote())
    const { quoteRequest } = storeToRefs(useQuoteRequest())
    const tab = ref<QuoteTabs>(QuoteTabs.REQUEST)
    const [showActivity, toggleActivity] = useToggle(!vuetify.display.mobile.value)
    const isReadonly = computed(() => useAuthentication().state.value.isReadonly)

    const { t } = useI18n()
    const pageTitle = computed(
      () =>
        `${t('quotes.quote')} ${
          quote.value
            ? quote.value.quote
              ? quote.value.quote.quoteNumber +
                (quote.value.quote.quoteVersion !== '1'
                  ? '-V' + quote.value.quote.quoteVersion
                  : '')
              : quote.value.name
            : undefined
        }`
    )
    useTitle(pageTitle)

    watch(currentRoute, () => {
      scrollToTop(undefined)
      useQuote().getQuote(currentRoute.params.id as string)
    })

    const isLoggedInQuotesPortal = computed(() => !isGuest.value && isQuotesPortal.value)

    const showRequestButton = computed(() => {
      return (
        tab.value === QuoteTabs.REQUEST &&
        quote.value &&
        quote.value.quoteStatus === QuoteStatus.DRAFT &&
        !quote.value.quote &&
        quoteRequest.value &&
        quoteRequest.value.items.length > 0
      )
    })

    watch(loading, () => {
      if (!loading.value && quote.value) {
        tab.value = quote.value.quote ? QuoteTabs.QUOTE : QuoteTabs.REQUEST
        if (!isQuotesPortal.value && quote.value.orderId) {
          useOrder().getOrder(quote.value.orderId)
        }
      }
    })

    const addressValidation = useAddressValidation()[AddressType.delivery]

    const updateTitle = (name: string) => {
      if (quote.value) {
        quote.value.name = name
        trackEvent(QuoteEvent.EditRequestTitle)
        useQuoteRequest().updateQuoteRequest(quote.value.id, { name })
      }
    }

    const noPositionSelected = computed(() => {
      return !quote.value?.quote?.items.find(
        (item) => (item.isOptional && item.isPickedOptional) || !item.isOptional
      )
    })

    const orderQuote = () => {
      if (quote.value) {
        if (isGuest.value) {
          useQuote().orderPlatformQuoteAsGuest(quote.value.id)
          trackEvent(GuestQuoteEvent.Accept)
        } else {
          useQuote().orderPlatformQuote(quote.value.id)
          trackEvent(QuoteEvent.Accept)
        }
      }
    }

    const rejectQuote = (rejectionComment: string) => {
      if (quote.value) {
        useQuote().rejectQuote(quote.value.id, rejectionComment)

        if (isGuest.value) {
          trackEvent(GuestQuoteEvent.Reject)
        } else {
          trackEvent(QuoteEvent.Decline)
        }
      }
    }

    const printQuote = () => {
      if (quote.value) {
        useQuote().printQuote(quote.value.id)

        if (isGuest.value) {
          trackEvent(GuestQuoteEvent.Print)
        } else {
          trackEvent(QuoteEvent.Download.Quote)
        }
      }
    }

    const navigateToQuotesOverview = () => {
      router.push('/quotes')
    }

    const isDuplicateUser = computed(() => {
      return isQuotesPortal && useGuestUser().guestUser.isDuplicateUser
    })

    return {
      addressValidation,
      archiving,
      dateNotOver,
      formatDate,
      getQuoteStatusColor,
      isDuplicateUser,
      isGuest,
      isLoggedInQuotesPortal,
      isQuotesPortal,
      isReadonly,
      loading,
      navigateToQuotesOverview,
      noPositionSelected,
      ordering,
      orderQuote,
      printing,
      printQuote,
      quote,
      quoteRequest,
      QuoteStatus,
      QuoteTabs,
      rejecting,
      rejectQuote,
      showActivity,
      showRequestButton,
      tab,
      toggleActivity,
      updateTitle,
      mdiArrowLeftBoldOutline,
      mdiMessageText,
      mdiHandshake
    }
  }
})
</script>
