<template>
  <v-form ref="form" v-model="valid">
    <v-card class="pw-orderitem-row py-1 px-2 mb-1">
      <v-row no-gutters>
        <v-col cols="auto" class="d-flex align-center mr-2">
          <product-image :url="orderItem.productImageUrl" />
        </v-col>
        <v-col class="d-flex justify-center">
          <v-row no-gutters>
            <v-col class="d-flex flex-column justify-center mr-1 mr-md-2">
              <router-link
                v-if="showLinkToProductPage"
                ref="title"
                :to="{
                  name: 'Product',
                  params: { id: orderItem.productId }
                }"
                class="text-body-1 text-decoration-none text-primary"
                v-html="orderItem.productTitle"
              />
              <span v-else ref="title" v-html="orderItem.productTitle" />
              <product-identifiers
                class="mb-1"
                :article-number="orderItem.articleNumber"
                :product-id="orderItem.productId"
              />
              <div class="d-flex flex-row flex-wrap align-center">
                <div class="mr-1">
                  <sf-text ref="supplier-name">{{ orderItem.supplierName }}</sf-text>
                  <template v-if="orderItem.subscriptionModel">
                    <sf-text class="mx-1">-</sf-text>
                    <sf-text>
                      <subscription-period :subscription-model="orderItem.subscriptionModel" />
                    </sf-text>
                  </template>
                </div>
                <div v-if="orderItem.serialNumbers.length > 0">
                  <order-item-serials-dialog :serials="orderItem.serialNumbers" />
                </div>
                <div v-if="orderItem.deliveryInfo">
                  <order-item-delivery-info-dialog :delivery-info="orderItem.deliveryInfo" />
                </div>
              </div>
              <div class="d-flex flex-column flex-sm-row">
                <cart-row-comment
                  v-if="orderItem.comment.value || editable"
                  ref="supplier-comment"
                  :comment="orderItem.comment.value"
                  :editable="editable"
                  :comment-field="portalSettings.supplierComment.field1"
                  :is-seller-comment="false"
                  :max-length="orderItem.comment.maxLength"
                  class="flex-grow-1 mt-3 mb-1 mr-sm-2"
                  @comment-changed="updateComment"
                />
                <cart-row-comment
                  v-if="
                    orderItem.sellerComment.value ||
                    (editable && Object.keys(portalSettings.sellerComments).length > 0)
                  "
                  ref="seller-comment"
                  :comment="orderItem.sellerComment.value"
                  :editable="editable"
                  :comment-field="{ required: requiredSellerComment }"
                  :max-length="orderItem.sellerComment.maxLength"
                  class="mt-3 mb-1"
                  @comment-changed="updateSellerComment"
                />
              </div>
            </v-col>
            <v-col class="hidden-md-and-up" cols="12">
              <v-divider class="my-1" />
            </v-col>
            <v-col align-self="center" cols="12" md="auto">
              <v-row ref="numbers" justify="end" no-gutters>
                <v-col
                  class="d-flex flex-column justify-center align-center"
                  :class="[{ 'mr-md-2': orderItem.price }]"
                  cols="auto"
                >
                  <quantity-stepper
                    v-if="editable"
                    :quantity="orderItem.quantity"
                    @change-quantity="updateQuantity"
                  />
                  <sf-heading v-if="!editable" ref="quantity-text" class="ml-2">
                    {{ orderItem.quantity }}x
                  </sf-heading>
                </v-col>
                <v-col
                  v-if="orderItem.price"
                  class="d-flex flex-column justify-center align-center align-md-end ml-3 ml-md-0"
                  cols="auto"
                  style="width: 120px"
                >
                  <sf-heading ref="price">
                    {{ formatMoney(orderItem.price, false, true) }}
                  </sf-heading>
                  <billing-period
                    v-if="orderItem.subscriptionModel"
                    class="text-body-1 text-medium-emphasis"
                    :subscription-model="orderItem.subscriptionModel"
                  />
                  <packaging-info
                    v-if="orderItem.packagingModel"
                    class="text-body-1 text-medium-emphasis text-right"
                    :packaging-model="orderItem.packagingModel"
                  />
                </v-col>

                <v-col
                  v-if="editable"
                  class="d-flex justify-center align-center ml-md-2"
                  cols="auto"
                >
                  <sf-delete-button
                    v-if="!lastItem"
                    :loading="deletingOrderItem"
                    @click="removeOrderItem"
                  />
                  <sf-delete-confirm-dialog
                    v-else
                    :title="$t('order.deleteOrderItem')"
                    :description="$t('order.deleteLastItemDialog')"
                    @confirm="removeLastOrderItem"
                  >
                    <template #activator="{ props }">
                      <sf-delete-button v-bind="props" />
                    </template>
                  </sf-delete-confirm-dialog>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-form>
</template>

<script lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import SfDeleteButton from '@/components/button/SfDeleteButton.vue'
import SfDeleteConfirmDialog from '@/components/dialog/SfDeleteConfirmDialog.vue'
import PackagingInfo from '@/components/PackagingInfo.vue'
import ProductImage from '@/components/ProductImage.vue'
import QuantityStepper from '@/components/QuantityStepper.vue'
import SubscriptionPeriod from '@/components/SubscriptionPeriod.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import CartRowComment from '@/modules/cart/components/CartRowComment.vue'
import OrderItemDeliveryInfoDialog from '@/modules/order/components/OrderItemDeliveryInfoDialog.vue'
import OrderItemSerialsDialog from '@/modules/order/components/OrderItemSerialsDialog.vue'
import { OrderItem } from '@/generatedTypes'
import useOrder from '@/modules/order/useOrder'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import ProductIdentifiers from '@/modules/product/components/ProductIdentifiers.vue'
import { formatMoney } from '@/modules/product/helpers'
import { VForm } from '@/types'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'OrderPosition',
  components: {
    BillingPeriod,
    CartRowComment,
    ProductImage,
    QuantityStepper,
    SubscriptionPeriod,
    PackagingInfo,
    ProductIdentifiers,
    SfText,
    OrderItemSerialsDialog,
    OrderItemDeliveryInfoDialog,
    SfHeading,
    SfDeleteConfirmDialog,
    SfDeleteButton
  },
  props: {
    orderId: {
      type: String,
      required: true
    },
    orderItem: {
      type: Object as PropType<OrderItem>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    lastItem: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const valid = ref(false)
    const form = ref<VForm>()
    const { portalSettings } = storeToRefs(usePortalSettings())
    const { deletingOrderItem } = storeToRefs(useOrder())

    const updateQuantity = (quantity: number) => {
      useOrder().updateOrderItem(props.orderId, props.orderItem.id, {
        quantity
      })
    }

    const updateComment = (comment: string) => {
      useOrder().updateOrderItem(props.orderId, props.orderItem.id, {
        comment
      })
    }

    const updateSellerComment = (sellerComment: string) => {
      useOrder().updateOrderItem(props.orderId, props.orderItem.id, {
        sellerComment
      })
    }

    const removeOrderItem = () => {
      useOrder().removeOrderItem(props.orderId, props.orderItem.id)
    }

    const removeLastOrderItem = () => {
      useOrder().removeLastOrderItem(props.orderId, props.orderItem.id)
    }

    const requiredSellerComment = computed(() => {
      return Object.values(portalSettings.value.sellerComments).some(
        (sellerComment) => sellerComment.required
      )
    })

    watch(valid, (newValue) => {
      useOrder().addCommentValid(props.orderItem.id, newValue)
    })

    const showLinkToProductPage = computed(
      () => props.orderItem.productId && props.orderItem.isInAssortment
    )

    return {
      form,
      valid,
      portalSettings,
      deletingOrderItem,
      formatMoney,
      updateQuantity,
      updateComment,
      updateSellerComment,
      removeOrderItem,
      removeLastOrderItem,
      requiredSellerComment,
      showLinkToProductPage
    }
  }
})
</script>
