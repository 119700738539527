import { ApiError, handleApiError } from '@/handleApiError'
import { isGuest, isQuotesPortal } from '@/helpers'
import useAuthentication from '@/modules/authentication/useAuthentication'
import api from '@/modules/tracking/api'
import { createEventContext, createWebVitalsContext } from '@/modules/tracking/helper'
import { Event, EventContext, WebVitalsContext } from '@/modules/tracking/types'
import { Metric, onCLS, onFID, onLCP } from 'web-vitals'

const SAMPLE_RATE = 1
let webVitalBuffer: WebVitalsContext[] = []
let trackingEventBuffer: EventContext[] = []

const trackEvent = async (event: Event): Promise<void> => {
  if (isQuotesPortal.value) {
    event = Object.assign({}, event, {
      category: `Angebotsportal: ${event.category}`
    })
  }

  const sendContext = (context: EventContext) =>
    api.trackUserInteraction(context, isGuest.value).catch((error: ApiError) => {
      handleApiError(error, {
        appearance: 'NONE'
      })
    })

  if (useAuthentication().hasValidAccessToken() || useAuthentication().hasValidGuestToken()) {
    await Promise.all(trackingEventBuffer.map(sendContext))
    trackingEventBuffer = []
  }

  if (shouldSampleMetric()) {
    const context = createEventContext(event)
    if (useAuthentication().hasValidAccessToken() || useAuthentication().hasValidGuestToken()) {
      await sendContext(context)
    } else {
      trackingEventBuffer.push(context)
    }
  }
}

const trackWebVitals = (): void => {
  onCLS(trackWebVitalsMetric)
  onFID(trackWebVitalsMetric)
  onLCP(trackWebVitalsMetric)
}

const trackWebVitalsMetric = async (metric: Metric) => {
  const sendContext = (context: WebVitalsContext) =>
    api.trackWebVitals(context).catch((error: ApiError) => {
      handleApiError(
        error,
        {
          appearance: 'NONE'
        },
        {
          errorCode: 401,
          logError: false
        }
      )
    })

  if (useAuthentication().hasValidAccessToken()) {
    await Promise.all(webVitalBuffer.map(sendContext))
    webVitalBuffer = []
  }

  if (shouldSampleMetric()) {
    const context = createWebVitalsContext(metric)
    if (useAuthentication().hasValidAccessToken()) {
      await sendContext(context)
    } else {
      webVitalBuffer.push(context)
    }
  }
}

const shouldSampleMetric = (): boolean => {
  return SAMPLE_RATE >= 1 || Math.random() <= SAMPLE_RATE
}

export { trackEvent, trackWebVitals }
