<template>
  <list-content class="mt-2">
    <v-col cols="12">
      <error-component
        v-if="mustHandleError"
        :message="error.message"
        :code="error.code"
        :transparent-background="true"
      />
      <order-documents-loading v-else-if="loading.documents" />
      <list-empty
        v-else-if="documents.length === 0"
        :icon="mdiFileDocumentOutline"
        :message="$t('noOrderDocumentsFound')"
      />
      <v-row v-else-if="documents.length > 0" dense justify="center">
        <v-col>
          <v-row dense class="flex-row-reverse">
            <v-col cols="auto">
              <v-btn
                ref="downloadAll"
                :loading="loading.downloadAllDocuments"
                :icon="$vuetify.display.smAndDown"
                variant="text"
                class="mr-2"
                color="primary"
                @click="downloadAllDocuments"
              >
                <v-icon :icon="mdiDownload" />
                <span class="d-none d-md-block">{{ $t('PDF') }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <order-document-row
                v-for="document in documents"
                :key="document.id"
                :order-document="document"
                :order-id="orderId"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </list-content>
</template>

<script lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { customErrorHandling } from '@/modules/error/helpers'
import OrderDocumentRow from '@/modules/order/components/OrderDocumentRow.vue'
import OrderDocumentsLoading from '@/modules/order/components/OrderDocumentsLoading.vue'
import useOrderDocuments from '@/modules/order/useOrderDocuments'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import { mdiDownload, mdiFileDocumentOutline } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'OrderDocuments',
  components: {
    ListContent,
    ListEmpty,
    OrderDocumentRow,
    OrderDocumentsLoading,
    ErrorComponent
  },
  props: {
    orderIdProp: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    const { error, mustHandleError } = customErrorHandling()
    const { documents, loading } = storeToRefs(useOrderDocuments())
    const currentRoute = useRoute()
    const orderId = ref(props.orderIdProp ? props.orderIdProp : (currentRoute.params.id as string))

    useOrderDocuments().getOrderTransactions(orderId.value)

    const downloadAllDocuments = () => {
      useOrderDocuments().loadAllPDFDocuments(orderId.value)
      trackEvent(OrderEvent.Download.All)
    }

    return {
      documents,
      downloadAllDocuments,
      error,
      loading,
      mustHandleError,
      orderId,
      mdiDownload,
      mdiFileDocumentOutline
    }
  }
})
</script>
