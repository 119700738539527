<template>
  <v-row dense>
    <v-col cols="12">
      <div
        :style="!$vuetify.display.mobile ? 'height: 36px' : ''"
        class="d-flex flex-row flex-wrap align-center"
      >
        <show-categories-button v-if="!hideShowCategoriesButton" class="mr-1" />
        <sf-title v-if="title" class="mr-2">{{ title }}</sf-title>
        <slot name="viewToggle" />
        <v-spacer />
        <slot name="search" />
        <slot name="filter" />
        <slot name="action" />
      </div>

      <v-divider class="my-1" />

      <template v-if="$slots.cols">
        <v-row class="d-none d-md-flex px-2" dense>
          <slot name="cols" />
        </v-row>

        <v-divider class="d-none d-md-block mt-1" />
      </template>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ShowCategoriesButton from '@/components/ShowCategoriesButton.vue'
import SfTitle from '@/components/text/SfTitle.vue'

export default defineComponent({
  name: 'ListHeader',
  components: { ShowCategoriesButton, SfTitle },
  props: {
    title: {
      type: String,
      default: undefined
    },
    hideShowCategoriesButton: {
      type: Boolean,
      default: false
    }
  }
})
</script>
