<template>
  <div>
    <list-header :title="$t('order.orders')">
      <template #viewToggle>
        <view-toggle
          :views="getOrdersViews()"
          :current-view="OrdersView.GRID"
          :toggle-event="toggleEvent"
          @changed="changeView"
        />
      </template>
    </list-header>
    <error-component
      v-if="mustHandleError"
      :code="error.code"
      :message="error.message"
      :transparent-background="true"
    />
    <template v-else>
      <v-row dense class="mr-1">
        <v-col v-for="shownOrderStatus in shownOrderStatuses" :key="shownOrderStatus">
          <orders-grid-column-header
            :order-status="columns[shownOrderStatus].orderStatus"
            :count="columns[shownOrderStatus].count"
            :loading="columns[shownOrderStatus].loading"
          />
        </v-col>
      </v-row>
      <list-content ref="listContent">
        <v-col v-for="shownOrderStatus in shownOrderStatuses" :key="shownOrderStatus">
          <orders-grid-column :column="columns[shownOrderStatus]" />
        </v-col>
        <v-col v-if="loadingNextPage" cols="12" class="d-flex justify-center">
          <v-progress-circular indeterminate :size="50" color="primary" class="ma-1" />
        </v-col>
      </list-content>
    </template>
  </div>
</template>

<script lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import ViewToggle from '@/components/ViewToggle.vue'
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { customErrorHandling } from '@/modules/error/helpers'
import { OrderStatus } from '@/generatedTypes'
import { OrderPreviewColumnsKey, OrdersView } from '@/modules/order/types'
import OrderEvent from '@/modules/tracking/events/order'
import router from '@/router'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  ComponentPublicInstance
} from 'vue'
import { storeToRefs } from 'pinia'
import { getOrdersViews } from '@/modules/order/helpers'
import useOrderPreviews from '@/modules/order/useOrderPreviews'
import OrdersGridColumn from '@/modules/order/components/OrdersGridColumn.vue'
import OrdersGridColumnHeader from '@/modules/order/components/OrdersGridColumnHeader.vue'

export default defineComponent({
  name: 'OrdersGrid',
  components: {
    ListContent,
    ListHeader,
    OrdersGridColumn,
    OrdersGridColumnHeader,
    ViewToggle,
    ErrorComponent
  },
  setup() {
    const { error, mustHandleError } = customErrorHandling()
    const columnOrderStatuses: OrderPreviewColumnsKey[] = [
      OrderStatus.ARRIVED,
      OrderStatus.PROCESSING,
      OrderStatus.ADVISED,
      OrderStatus.INVOICED,
      OrderStatus.ERROR
    ]
    const { columns, loadingNextPage } = storeToRefs(useOrderPreviews())
    const page = ref(1)
    const listContent = ref<ComponentPublicInstance>()

    onBeforeMount(() => {
      for (const columnOrderStatus of columnOrderStatuses) {
        useOrderPreviews().getOrderPreviews(columnOrderStatus, page.value)
      }
    })

    const shownOrderStatuses = computed<OrderPreviewColumnsKey[]>(() => {
      const errorColumn = columns.value[OrderStatus.ERROR]
      if (errorColumn?.count === 0) {
        return [
          OrderStatus.ARRIVED,
          OrderStatus.PROCESSING,
          OrderStatus.ADVISED,
          OrderStatus.INVOICED
        ]
      }
      return columnOrderStatuses as OrderPreviewColumnsKey[]
    })

    const getNextPage = () => {
      page.value = page.value + 1
      for (const columnOrderStatus of columnOrderStatuses) {
        useOrderPreviews().getAdditionalPage(columnOrderStatus, page.value)
      }
    }

    const changeView = (view: OrdersView) => {
      router.push({ query: { view: view } })
    }

    onMounted(() => {
      if (listContent.value) {
        const listContentElement = listContent.value.$el
        listContentElement.addEventListener('scroll', () => {
          if (
            listContentElement.scrollTop + listContentElement.clientHeight >=
            listContentElement.scrollHeight
          ) {
            getNextPage()
          }
        })
      }
    })

    return {
      toggleEvent: OrderEvent.ToggleView,
      listContent,
      columns,
      loadingNextPage,
      OrderStatus,
      OrdersView,
      columnOrderStatuses,
      shownOrderStatuses,
      getNextPage,
      changeView,
      getOrdersViews,
      error,
      mustHandleError
    }
  }
})
</script>
