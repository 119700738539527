<template>
  <v-dialog v-model="dialog" :width="500">
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ title }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <slot name="content" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import SfHeading from '@/components/text/SfHeading.vue'

export default defineComponent({
  name: 'SfInfoDialog',
  components: { SfHeading },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const dialog = ref(false)

    const close = () => {
      dialog.value = false
    }

    return {
      dialog,
      close
    }
  }
})
</script>
