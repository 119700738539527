import { OrdersView } from '@/modules/order/types'
import { Event } from '@/modules/tracking/types'

export default class OrderEvent extends Event {
  static readonly TextSearchInstantSearch = new OrderEvent(
    'Bestellungen durchsucht',
    'Über die Instant search'
  )
  static readonly TextSearch = new OrderEvent(
    'Bestellungen durchsucht',
    'Über die Suchleiste in der Liste der Bestellungen'
  )
  static readonly InstantSearch = new OrderEvent('Bestellung in Instant Search ausgewählt')
  static readonly OpenSerialNumbers = new OrderEvent('Seriennummern-Dialog geöffnet')
  static readonly CopySerialNumbers = new OrderEvent('Alle Seriennummern einer Position kopiert')
  static readonly OpenParcelTracking = new OrderEvent('Lieferung verfolgen Dialog geöffnet')
  static readonly OpenParcelTrackingLink = new OrderEvent('Paketverfolgungslink geöffnet')
  static readonly Download = class {
    static readonly All = new OrderEvent('Alle Bestelldokumente wurden heruntergeladen.')
    static readonly Delivery = new OrderEvent('Ein Lieferavis wurde heruntergeladen.')
    static readonly Confirmation = new OrderEvent('Eine Bestellbestätigung wurde heruntergeladen.')
    static readonly Order = new OrderEvent('Eine Bestellung wurde heruntergeladen.')
    static readonly FailedOrder = new OrderEvent(
      'Eine nicht sendbare Bestellung wurde heruntergeladen.'
    )
    static readonly Invoice = new OrderEvent('Eine Rechnung wurde heruntergeladen.')
  }
  static readonly Approve = new OrderEvent('Freizugebende Bestellung akzeptiert')
  static readonly RequestApproval = new OrderEvent('Freizugebende Bestellung erneut angefragt')
  static readonly DeclineApproval = new OrderEvent('Freizugebende Bestellung abgelehnt')
  static readonly Request = new OrderEvent('Ausstehende Bestellung angefragt')
  static readonly Send = new OrderEvent('Ausstehende Bestellung abgeschickt')
  static readonly Decline = new OrderEvent('Ausstehende Bestellung abgelehnt')
  static readonly SendAndPay = new OrderEvent('Bezahlung via Sofortüberweisung initiiert')
  static readonly Archive = new OrderEvent('Archiviert')
  static readonly Dearchive = new OrderEvent('Dearchiviert')
  static readonly Sort = class {
    static readonly Date = class {
      static readonly Ascending = new OrderEvent('Sortiert nach Datum', 'Aufsteigend')
      static readonly Descending = new OrderEvent('Sortiert nach Datum', 'Absteigend')
    }
    static readonly Status = class {
      static readonly Ascending = new OrderEvent('Sortiert nach Status', 'Aufsteigend')
      static readonly Descending = new OrderEvent('Sortiert nach Status', 'Absteigend')
    }
  }

  static readonly ToggleView = (view: string): OrderEvent => {
    if (view === OrdersView.GRID) {
      return new OrderEvent('Darstellungsansicht umgeschaltet', 'Kachelansicht')
    }
    if (view === OrdersView.LIST) {
      return new OrderEvent('Darstellungsansicht umgeschaltet', 'Listenansicht')
    }
    if (view === OrdersView.APPROVALS) {
      return new OrderEvent('Darstellungsansicht umgeschaltet', 'Freizugebende Bestellungen')
    }
    return new OrderEvent('Darstellungsansicht umgeschaltet', view)
  }
  static readonly AddToCart = new OrderEvent('Zum Warenkorb hinzugefügt')

  private constructor(action: string, label?: string) {
    super('Bestellungen', action, label ?? '')
  }
}
