<template>
  <v-card class="pa-2">
    <v-row id="personalData" dense>
      <v-col cols="12">
        <sf-heading class="mb-1">{{ $t('personalData') }}</sf-heading>
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="12" md="6">
            <v-row dense>
              <v-col cols="12">
                <sf-sub-heading>{{ $t('user.avatar') }}</sf-sub-heading>
              </v-col>
              <v-col cols="12" class="d-flex flex-column">
                <v-avatar
                  size="80"
                  :class="[{ cursor: isShopAdmin || isOwnUser }]"
                  @click="openFile"
                >
                  <v-img v-if="user.avatarUrl" :src="fitImage(user.avatarUrl, 160, 160, '&')" />
                  <vue-avatar v-if="!user.avatarUrl" :username="getFullName(user)" :size="80" />
                  <template v-if="isShopAdmin || isOwnUser">
                    <v-btn icon size="x-large" class="change-button">
                      <v-icon :icon="mdiCamera" />
                    </v-btn>
                    <v-file-input
                      name="file-input"
                      class="fileInput"
                      accept="image/*"
                      @update:model-value="fileChanged"
                    />
                  </template>
                </v-avatar>
                <sf-text v-if="(isShopAdmin || isOwnUser) && maxFileUploadSize" class="mt-1">
                  {{ $t('max') }} {{ maxFileUploadSize }}
                </sf-text>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="isOwnUser" cols="12" md="6">
            <v-row dense>
              <v-col cols="12">
                <sf-sub-heading>{{ $t('localization') }}</sf-sub-heading>
              </v-col>
              <v-col cols="12">
                <v-select
                  ref="language"
                  v-model="userModel.alpha2language"
                  color="primary"
                  :items="getLanguages()"
                  item-title="translation"
                  item-value="title"
                  density="compact"
                  persistent-hint
                  variant="underlined"
                  :hint="$t('user.language')"
                  autocomplete="off"
                  @update:model-value="changeLanguage"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense>
            <v-col cols="12">
              <sf-sub-heading>{{ $t('coreData') }}</sf-sub-heading>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                ref="title"
                v-model="userModel.title"
                color="primary"
                :items="getContactTitles()"
                item-title="translation"
                item-value="title"
                density="compact"
                persistent-hint
                variant="underlined"
                :hint="$t('user.contactTitle')"
                :disabled="!isShopAdmin && !isOwnUser"
                autocomplete="honorific-prefix"
                @update:model-value="sendChange('title')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <sf-debounced-text-field
                ref="mail"
                v-model="userModel.email"
                density="compact"
                disabled
                persistent-hint
                autocomplete="off"
                :hint="$t('user.email')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <sf-debounced-text-field
                ref="first-name"
                v-model="userModel.firstName"
                density="compact"
                persistent-hint
                :hint="$t('user.firstName') + '*'"
                :rules="[rules.nameRule, rules.requiredRule]"
                :disabled="!isShopAdmin && !isOwnUser"
                autocomplete="given-name"
                @debounce="sendChange('name')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <sf-debounced-text-field
                ref="last-name"
                v-model="userModel.lastName"
                density="compact"
                persistent-hint
                :hint="$t('user.lastName') + '*'"
                :rules="[rules.nameRule, rules.requiredRule]"
                :disabled="!isShopAdmin && !isOwnUser"
                autocomplete="family-name"
                @debounce="sendChange('lastName')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                ref="department"
                v-model="userModel.department"
                color="primary"
                :items="getDepartmentTitles()"
                item-title="translation"
                item-value="title"
                density="compact"
                persistent-hint
                variant="underlined"
                :hint="$t('user.department')"
                :disabled="!isShopAdmin && !isOwnUser"
                @update:model-value="sendChange('department')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <sf-debounced-text-field
                ref="phone"
                v-model="userModel.phone"
                density="compact"
                persistent-hint
                :hint="$t('user.phone')"
                :rules="[rules.phoneRule]"
                :disabled="!isShopAdmin && !isOwnUser"
                autocomplete="work tel"
                @debounce="sendChange('phone')"
              />
            </v-col>
            <v-col cols="12" md="6">
              <sf-debounced-text-field
                ref="phone-mobile"
                v-model="userModel.phoneMobile"
                density="compact"
                persistent-hint
                :hint="$t('user.phoneMobile')"
                :rules="[rules.phoneRule]"
                :disabled="!isShopAdmin && !isOwnUser"
                autocomplete="mobile tel"
                @debounce="sendChange('phoneMobile')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import SfDebouncedTextField from '@/components/input/SfDebouncedTextField.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { fitImage, getFullName } from '@/helpers'
import { trackingForUser } from '@/modules/tracking/events/helper/userTrackingHelper'
import { trackEvent } from '@/modules/tracking/useTracking'
import { getContactTitles, getDepartmentTitles, getLanguages } from '@/modules/user/helpers'
import { User } from '@/generatedTypes'
import rules from '@/rules'
import { VForm } from '@/types'
import { mdiCamera } from '@mdi/js'
import VueAvatar from '@webzlodimir/vue-avatar'
import '@webzlodimir/vue-avatar/dist/style.css'
import { clone } from 'lodash-es'
import { PropType, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'UserData',
  components: {
    VueAvatar,
    SfText,
    SfSubHeading,
    SfHeading,
    SfDebouncedTextField
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    isOwnUser: {
      type: Boolean,
      default: false
    },
    isShopAdmin: {
      type: Boolean,
      default: false
    },
    maxFileUploadSize: {
      type: String,
      default: undefined
    }
  },
  emits: ['userChanged', 'languageChanged', 'avatarChanged'],
  setup(props, { emit }) {
    const form = ref<VForm>()
    const valid = ref(false)
    const userModel = ref(clone(props.user))

    const changeLanguage = () => {
      emit('languageChanged', userModel.value.alpha2language)
      trackEvent(trackingForUser(props.user.id).changedLanguage(userModel.value.alpha2language))
    }

    const sendChange = (
      trackField: 'title' | 'name' | 'lastName' | 'department' | 'phoneMobile' | 'phone' | 'mail'
    ) => {
      if (form.value && form.value.validate()) {
        emit('userChanged', userModel.value)
        let event
        const trackingEvents = trackingForUser(props.user.id)
        switch (trackField) {
          case 'title':
            event = trackingEvents.changedUserData.title
            break
          case 'name':
            event = trackingEvents.changedUserData.name
            break
          case 'lastName':
            event = trackingEvents.changedUserData.lastName
            break
          case 'department':
            event = trackingEvents.changedUserData.department
            break
          case 'phoneMobile':
            event = trackingEvents.changedUserData.phoneMobile
            break
          case 'phone':
            event = trackingEvents.changedUserData.phone
            break
          case 'mail':
            event = trackingEvents.changedUserData.mail
            break
          default:
            return
        }
        trackEvent(event)
      }
    }

    const openFile = () => {
      document.getElementsByName('file-input')[0].click()
    }

    const fileChanged = (file: File[] | File) => {
      emit('avatarChanged', file)

      trackEvent(trackingForUser(props.user.id).changedAvatar())
    }

    return {
      form,
      valid,
      rules,
      userModel,
      getDepartmentTitles,
      getContactTitles,
      getLanguages,
      changeLanguage,
      sendChange,
      openFile,
      fileChanged,
      getFullName,
      fitImage,
      mdiCamera
    }
  }
})
</script>

<style lang="scss" scoped>
:deep() {
  .v-input__slot {
    &::before {
      display: none;
    }
  }
  .v-text-field__slot,
  .v-select__slot {
    border-bottom: 1px solid;
  }
}

.img-upload {
  position: absolute;
  top: 0;
  left: 0;
}

.v-avatar {
  .cursor {
    cursor: pointer;
  }

  .change-button {
    background-color: transparent;
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .fileInput {
    :deep() {
      .v-input__control,
      .v-input__prepend,
      .v-input__details {
        display: none;
      }
    }
  }

  &:hover {
    .change-button {
      display: inline;
    }
  }
}
</style>
