<template>
  <sf-info-dialog :title="$t('price.packaging')">
    <template #activator="{ props }">
      <sf-text-button v-bind="props">
        <v-icon start :icon="mdiPackageVariantClosed" />
        {{ $t('price.packaging') }}
      </sf-text-button>
    </template>
    <template #content>
      <div v-if="packaging.priceQuantity > 1" class="d-flex flex-column mb-1">
        <sf-sub-heading>
          {{ $t('price.packaging_priceQuantity') }}
          <span class="font-weight-bold">
            {{ packaging.priceQuantity }}
          </span>
        </sf-sub-heading>
        <sf-text>{{ $t('price.packaging_priceQuantity_label') }}</sf-text>
      </div>

      <div v-if="packaging.orderUnit" class="d-flex flex-column mb-1">
        <sf-sub-heading>
          {{ $t('price.packaging_orderUnit') }}
          <span class="font-weight-bold">{{ packaging.orderUnit }}</span>
        </sf-sub-heading>
        <sf-text>{{ $t('price.packaging_orderUnit_label') }}</sf-text>
      </div>

      <div v-if="packaging.numberContentUnitPerOrderUnit > 1" class="d-flex flex-column mb-1">
        <sf-sub-heading>
          {{ $t('price.packaging_numberContentUnitPerOrderUnit') }}
          <span class="font-weight-bold">
            {{ packaging.numberContentUnitPerOrderUnit }}
          </span>
        </sf-sub-heading>
        <sf-text>
          {{ $t('price.packaging_numberContentUnitPerOrderUnit_label') }}
        </sf-text>
      </div>

      <div v-if="packaging.contentUnit" class="d-flex flex-column mb-1">
        <sf-sub-heading>
          {{ $t('price.packaging_contentUnit') }}
          <span class="font-weight-bold">
            {{ packaging.contentUnit }}
          </span>
        </sf-sub-heading>
        <sf-text>{{ $t('price.packaging_contentUnit_label') }}</sf-text>
      </div>

      <div v-if="packaging.minQuantity > 1" class="d-flex flex-column mb-1">
        <sf-sub-heading>
          {{ $t('price.packaging_minQuantity') }}
          <span class="font-weight-bold">
            {{ packaging.minQuantity }}
          </span>
        </sf-sub-heading>
        <sf-text>{{ $t('price.packaging_minQuantity_label') }}</sf-text>
      </div>

      <div v-if="packaging.intervalQuantity > 1" class="d-flex flex-column mb-1">
        <sf-sub-heading>
          {{ $t('price.packaging_intervalQuantity') }}
          <span class="font-weight-bold">
            {{ packaging.intervalQuantity }}
          </span>
        </sf-sub-heading>
        <sf-text>{{ $t('price.packaging_intervalQuantity_label') }}</sf-text>
      </div>

      <div v-if="packaging.supplierPackingQuantity > 1" class="d-flex flex-column mb-1">
        <sf-sub-heading>
          {{ $t('price.packaging_supplierPackingQuantity') }}
          <span class="font-weight-bold">
            {{ packaging.supplierPackingQuantity }}
          </span>
        </sf-sub-heading>
        <sf-text>{{ $t('price.packaging_supplierPackingQuantity_label') }}</sf-text>
      </div>
    </template>
  </sf-info-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfInfoDialog from '@/components/dialog/SfInfoDialog.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { PackagingModel } from '@/generatedTypes'
import { mdiPackageVariantClosed } from '@mdi/js'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'ProductPackaging',
  components: { SfInfoDialog, SfTextButton, SfSubHeading, SfText },
  props: {
    packaging: {
      type: Object as PropType<PackagingModel>,
      required: true
    }
  },
  setup() {
    const dialog = ref(false)

    return {
      dialog,
      mdiPackageVariantClosed
    }
  }
})
</script>
