<template>
  <v-list nav density="compact">
    <side-menu-item
      v-for="item in filterMenuItems(menu, portalSettings)"
      :key="item.key"
      :menu-item="item"
    />
  </v-list>
</template>

<script lang="ts">
import { filterMenuItems } from '@/helpers'
import { PortalSettings } from '@/modules/portalSettings/types'
import { defineComponent, PropType } from 'vue'
import { MenuItem } from '@/components/navigation/menu'
import SideMenuItem from '@/components/navigation/SideMenuItem.vue'

export default defineComponent({
  name: 'SideMenu',
  components: {
    SideMenuItem
  },
  props: {
    menu: {
      type: Array as PropType<MenuItem[]>,
      default: () => []
    },
    portalSettings: {
      type: Object as PropType<PortalSettings>,
      required: true
    }
  },
  setup() {
    return {
      filterMenuItems
    }
  }
})
</script>
