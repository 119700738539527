<template>
  <v-autocomplete
    ref="input"
    :custom-filter="customShortUserFilter"
    :items="users"
    :model-value="modelValue ? [modelValue] : []"
    color="primary"
    item-value="id"
    item-title="firstName"
    variant="filled"
    hide-details
    return-object
    :no-data-text="$t('orderLimits.noEmployeeFound')"
    multiple
    clear-on-select
    @update:model-value="onUpdate"
  >
    <template #selection="{ item }">
      <div class="ml-1">
        <avatar-image ref="avatar" :size="30" :user="item.raw" class="mr-2 my-1" />
        <span ref="name" class="text-body-1">
          {{ getFullName(item.raw) }}
        </span>
      </div>
    </template>
    <template #item="{ props, item }">
      <v-list-item v-bind="props" :title="getFullName(item.raw)">
        <template #prepend>
          <avatar-image ref="avatar" :size="30" :user="item.raw" left />
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import { fitCropImage, getFullName } from '@/helpers'
import { customShortUserFilter } from '@/modules/user/helpers'
import { ShortUser } from '@/generatedTypes'
import { PropType, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'CollectionAssigneeSelect',
  components: { AvatarImage },
  props: {
    modelValue: {
      type: Object as PropType<ShortUser | undefined>,
      required: true
    },
    users: {
      type: Array as PropType<ShortUser[]>,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const input = ref<HTMLInputElement>()

    const onUpdate = (val: ShortUser[]) => {
      emit('update:modelValue', val.length ? val[val.length - 1] : undefined)
      input.value?.blur()
    }

    return {
      input,
      onUpdate,
      fitCropImage,
      getFullName,
      customShortUserFilter
    }
  }
})
</script>
