<template>
  <v-card variant="outlined" class="pa-2" color="grey-lighten-2">
    <v-row dense>
      <v-col cols="12" sm="5" xl="4" align-self="center">
        <router-link :to="{ name: 'Product', params: { id: product.productId } }">
          <v-img
            :lazy-src="emptyPlaceholderImage"
            :src="product.imageUrl ? fitCropImage(product.imageUrl, 800, 800) : placeholderImage"
          />
        </router-link>
      </v-col>
      <v-col cols="12" sm="7" xl="8" class="d-flex flex-column">
        <sf-text ref="title" class="text-justify mb-1">
          {{ product.title }}
        </sf-text>
        <product-identifiers
          ref="identifiers"
          class="mb-5 text-high-emphasis"
          :article-number="product.articleNumber"
          :product-id="product.productId"
          large
        />
        <product-price
          class="text-high-emphasis"
          :price="product.price"
          :stock="product.stock"
          :product-id="product.productId"
          :suppliers="[]"
          :add-to-cart-event="addToCartEvent"
          :condition="product.condition"
          :loading-realtime-prices="false"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <products-list-detail-sources :product-id="product.productId" :sources="product.suppliers" />
    </v-row>
  </v-card>
</template>

<script lang="ts">
import SfText from '@/components/text/SfText.vue'
import { emptyPlaceholderImage, fitCropImage, placeholderImage } from '@/helpers'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import ProductIdentifiers from '@/modules/product/components/ProductIdentifiers.vue'
import ProductPrice from '@/modules/product/components/ProductPrice.vue'
import ProductsListDetailSources from '@/modules/product/components/ProductsListDetailSources.vue'
import { MediumProduct } from '@/generatedTypes'
import CtoEvent from '@/modules/tracking/events/cto'
import { storeToRefs } from 'pinia'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'CatalogProductCard',
  components: {
    ProductPrice,
    ProductIdentifiers,
    ProductsListDetailSources,
    SfText
  },
  props: {
    product: {
      type: Object as PropType<MediumProduct>,
      required: true
    }
  },
  setup() {
    const { portalSettings } = storeToRefs(usePortalSettings())

    return {
      addToCartEvent: CtoEvent.AddToCart,
      emptyPlaceholderImage,
      placeholderImage,
      portalSettings,
      fitCropImage
    }
  }
})
</script>
