<template>
  <v-autocomplete
    v-model="selectedProduct"
    v-model:search="searchTerm"
    class="no-menu-icon"
    :loading="loading"
    :items="items"
    :placeholder="$t('addProducts')"
    variant="solo"
    clearable
    hide-no-data
    hide-selected
    hide-details
    no-filter
    color="primary"
    item-value="[title,ean,productId,manufacturerProductId]"
    item-title="title"
    return-object
    :menu-props="menuProps"
    @update:focused="
      (focus) => {
        !focus ? resetInput : ''
      }
    "
  >
    <template #prepend-inner="{ isFocused }">
      <v-icon :color="isFocused.value ? 'primary' : ''" :icon="mdiMagnify" />
    </template>
    <template #item="{ item, props }">
      <instant-search-product-item
        v-bind="props"
        :item="item.raw"
        :search-term="searchTerm"
        dense
      />
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { emptyPlaceholderImage, fitCropImage, placeholderImage } from '@/helpers'
import { MediumProduct } from '@/generatedTypes'
import InstantSearchProductItem from '@/modules/search/components/InstantSearchProductItem.vue'
import useInstantProductSearch from '@/modules/search/useInstantProductSearch'
import { mdiMagnify } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'InstantProductSearchInput',
  components: { InstantSearchProductItem },
  emits: ['productSelected'],
  setup(_, { emit }) {
    const selectedProduct = ref<MediumProduct>()
    const timerId = ref<ReturnType<typeof setTimeout>>()
    const searchTerm = ref<string>()
    const { items, loading } = storeToRefs(useInstantProductSearch())

    const resetInput = () => {
      searchTerm.value = undefined
      selectedProduct.value = undefined
      useInstantProductSearch().clearItems()
    }

    watch(searchTerm, (value: string | undefined) => {
      clearTimeout(timerId.value)
      timerId.value = setTimeout(() => {
        if (value == '') {
          resetInput()
        } else if (value && value.length < 2) {
          useInstantProductSearch().clearItems()
        } else if (value && value.length >= 2) {
          useInstantProductSearch().instantSearch(value)
        }
      }, 400)
    })

    watch(selectedProduct, (value: MediumProduct | undefined) => {
      if (value) {
        emit('productSelected', value.productId)
        resetInput()
      }
    })

    const menuProps = { maxHeight: window.innerHeight * 0.6 }

    return {
      items,
      selectedProduct,
      loading,
      searchTerm,
      fitCropImage,
      emptyPlaceholderImage,
      placeholderImage,
      resetInput,
      menuProps,
      mdiMagnify
    }
  }
})
</script>
