<template>
  <div class="d-flex order-first order-sm-last mb-2 mb-sm-0 align-self-end">
    <div ref="headlines" class="d-flex flex-column mr-4">
      <sf-text v-if="shippingCost.value > 0">{{ $t('order.shippingCostsNet') }}</sf-text>
      <sf-text v-if="quantitySurcharge && quantitySurcharge.value">{{
        $t('order.quantitySurcharge')
      }}</sf-text>
      <sf-text>{{ $t('order.totalCostsNet') }}</sf-text>
      <template v-for="(totalTax, index) in orderSumInformation.totalTaxes" :key="index">
        <sf-text ref="additional-tax">
          {{ $t('additionalTax', [totalTax.vatPercent]) }}
        </sf-text>
      </template>
      <sf-text>{{ $t('order.totalCostsGross') }}</sf-text>
    </div>
    <div class="d-flex flex-column align-end">
      <sf-text v-if="shippingCost.value > 0" ref="shipping-cost">
        {{ formatMoney(shippingCost, false, true) }}
      </sf-text>
      <sf-text v-if="quantitySurcharge && quantitySurcharge.value" ref="quantity-surcharges">
        {{ formatMoney(quantitySurcharge, false, true) }}
      </sf-text>
      <sf-heading ref="net-sum">
        {{ formatMoney(orderSumInformation.netSum, false, true) }}
      </sf-heading>
      <template v-for="(totalTax, index) in orderSumInformation.totalTaxes" :key="index">
        <sf-text ref="tax-total">
          {{ formatMoney(totalTax.vatSum, false, true) }}
        </sf-text>
      </template>
      <sf-text ref="gross-sum">
        {{ formatMoney(orderSumInformation.grossSum, false, true) }}
      </sf-text>
    </div>
  </div>
</template>

<script lang="ts">
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { OrderSumInformation, Money } from '@/generatedTypes'
import { formatMoney } from '@/modules/product/helpers'
import { formatDate } from '@/utils/timeFormatHelpers'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'OrderInfoNetTable',
  components: { SfText, SfHeading },
  props: {
    shippingCost: {
      type: Object as PropType<Money>,
      required: true
    },
    quantitySurcharge: {
      type: Object as PropType<Money>,
      default: undefined
    },
    orderSumInformation: {
      type: Object as PropType<OrderSumInformation>,
      required: true
    }
  },
  setup() {
    return {
      formatDate,
      formatMoney
    }
  }
})
</script>
