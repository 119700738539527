<template>
  <v-dialog :model-value="true" :width="300" persistent>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('checkoutExpiredDialog.title') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text class="d-flex flex-column align-center text-justify">
        <v-icon :icon="mdiBellRing" size="56" color="orange" />
        <sf-text>{{ $t('checkoutExpiredDialog.description') }}</sf-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button :loading="loading" color="primary" @click="$emit('refresh')">
          {{ $t('checkoutExpiredDialog.buttonCaption') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { mdiBellRing } from '@mdi/js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CheckoutExpiredDialog',
  components: { SfHeading, SfTextButton, SfText },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refresh'],
  setup() {
    return {
      mdiBellRing
    }
  }
})
</script>
