<template>
  <div class="d-flex flex-row flex-md-nowrap flex-wrap-reverse">
    <div v-if="$slots.button" class="flex-grow-1 flex-row d-flex flex-sm-nowrap flex-wrap-reverse">
      <slot name="button" />
      <slot name="terms" />
    </div>
    <price-table
      :big-sum="bigSum"
      :gross-portal="grossPortal"
      :cart-info="cartInfo"
      :is-supplier-cart="isSupplierCart"
      class="flex-shrink-0"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { CartInfo } from '@/generatedTypes'
import PriceTable from '@/modules/cart/components/PriceTable.vue'

export default defineComponent({
  name: 'CartInfo',
  components: {
    PriceTable
  },
  props: {
    cartInfo: {
      type: Object as PropType<CartInfo>,
      required: true
    },
    grossPortal: {
      type: Boolean,
      required: true
    },
    isSupplierCart: {
      type: Boolean,
      required: true
    },
    bigSum: {
      type: Boolean,
      default: false
    }
  }
})
</script>
