<template>
  <v-card class="py-1 px-2 mb-1">
    <v-row no-gutters>
      <v-col cols="auto" class="d-flex align-center mr-2">
        <product-image ref="image" :url="collection.productImageUrl" />
      </v-col>
      <v-col class="d-flex justify-center">
        <v-row no-gutters>
          <v-col ref="names" class="d-flex flex-column justify-center mr-1 mr-md-2">
            <sf-text v-if="!collection.productTitle">
              {{ $t('productNoLongerAvailable') }}
            </sf-text>
            <router-link
              v-else
              ref="title"
              class="text-body-1 text-decoration-none text-primary"
              :to="{
                name: 'Product',
                params: { id: collection.productId }
              }"
            >
              {{ collection.productTitle }}
            </router-link>
            <product-identifiers
              :article-number="collectionItem.articleNumber"
              :product-id="collectionItem.productId"
            />
            <subscription-period
              v-if="collection.price && collection.price.subscriptionModel"
              class="text-body-1 text-medium-emphasis"
              :subscription-model="collection.price.subscriptionModel"
            />
            <div class="mt-2 mb-1" style="max-width: 300px">
              <span ref="comment">
                <sf-debounced-textarea
                  v-model="collection.comment"
                  :label="$t('common.comment')"
                  rows="1"
                  auto-grow
                  hide-details
                  density="compact"
                  variant="underlined"
                  autocomplete="off"
                  @debounce="sendComment"
                />
              </span>
            </div>
          </v-col>
          <v-col class="hidden-md-and-up" cols="12">
            <v-divider class="my-1" />
          </v-col>
          <v-col cols="12" md="auto" align-self="center">
            <v-row ref="numbers" no-gutters justify="end">
              <v-col cols="auto" class="d-flex flex-column justify-center align-center mr-md-2">
                <quantity-stepper
                  :quantity="collection.quantity"
                  :stock="collection.stock ? collection.stock.count : 0"
                  :packaging="collection.price ? collection.price.packagingModel : undefined"
                  @change-quantity="changeQuantity"
                  @blur="updatingQuantity = false"
                  @focus="updatingQuantity = true"
                />
                <stock-status-label
                  v-if="collection.stock"
                  class="mt-1"
                  :stock="collection.stock"
                />
              </v-col>
              <v-col
                cols="auto"
                style="width: 120px"
                class="d-flex flex-column justify-center align-center align-md-end ml-3 ml-md-0"
              >
                <template v-if="collectionItem.price">
                  <sf-heading>
                    {{ formatMoney(collectionItem.price.resellPrice, true) }}
                  </sf-heading>
                  <billing-period
                    v-if="collectionItem.price.subscriptionModel"
                    class="text-body-1 text-medium-emphasis"
                    :subscription-model="collectionItem.price.subscriptionModel"
                  />
                  <packaging-info
                    v-if="collectionItem.price.packagingModel"
                    class="text-body-1 text-medium-emphasis text-right"
                    :packaging-model="collectionItem.price.packagingModel"
                  />
                </template>
              </v-col>
              <v-col
                cols="auto"
                style="width: 36px"
                class="d-flex justify-center align-center ml-md-2"
              >
                <cart-button
                  v-if="collectionItem.price"
                  :is-icon="true"
                  :is-request="collectionItem.price.resellPrice.value <= 0"
                  :product-id="collectionItem.productId"
                  :quantity="collectionItem.quantity"
                  :supplier-row-id="collectionItem.price.supplierRowId"
                  :on-click-event="addToCartEvent"
                  :updating-quantity
                />
              </v-col>
              <v-col
                cols="auto"
                style="width: 36px"
                class="d-flex justify-center align-center ml-md-2"
              >
                <sf-icon-button color="" :icon="mdiTrashCanOutline" @click="removeProduct" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import CartButton from '@/components/button/CartButton.vue'
import SfIconButton from '@/components/button/SfIconButton.vue'
import SfDebouncedTextarea from '@/components/input/SfDebouncedTextarea.vue'
import PackagingInfo from '@/components/PackagingInfo.vue'
import ProductImage from '@/components/ProductImage.vue'
import QuantityStepper from '@/components/QuantityStepper.vue'
import StockStatusLabel from '@/components/StockStatusLabel.vue'
import SubscriptionPeriod from '@/components/SubscriptionPeriod.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { CollectionItem } from '@/generatedTypes'
import ProductIdentifiers from '@/modules/product/components/ProductIdentifiers.vue'
import { formatMoney, getStatusColor } from '@/modules/product/helpers'
import CollectionEvent from '@/modules/tracking/events/collection'
import { trackEvent } from '@/modules/tracking/useTracking'
import { mdiTrashCanOutline } from '@mdi/js'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'CollectionPosition',
  components: {
    BillingPeriod,
    CartButton,
    ProductImage,
    QuantityStepper,
    SubscriptionPeriod,
    PackagingInfo,
    ProductIdentifiers,
    SfIconButton,
    StockStatusLabel,
    SfDebouncedTextarea,
    SfText,
    SfHeading
  },
  props: {
    collectionItem: {
      type: Object as PropType<CollectionItem>,
      required: true
    }
  },
  emits: ['commentChanged', 'remove', 'changeQuantity'],
  setup(props, { emit }) {
    const collection = ref(props.collectionItem)
    const updatingQuantity = ref(false)

    const sendComment = () => {
      emit('commentChanged', {
        id: props.collectionItem.productId,
        comment: props.collectionItem.comment
      })
    }

    const removeProduct = () => {
      trackEvent(CollectionEvent.RemoveProduct)
      emit('remove', props.collectionItem.productId)
    }

    const changeQuantity = (quantity: number) => {
      emit('changeQuantity', {
        id: props.collectionItem.productId,
        quantity: quantity
      })
    }

    const addToCartEvent = CollectionEvent.AddProductToCart

    return {
      collection,
      getStatusColor,
      sendComment,
      removeProduct,
      changeQuantity,
      formatMoney,
      updatingQuantity,
      addToCartEvent,
      mdiTrashCanOutline
    }
  }
})
</script>
