<template>
  <sf-alert-base v-bind="{ icon: mdiInformationOutline, ...$attrs }" type="info" color="blue-grey">
    <slot />
  </sf-alert-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SfAlertBase from '@/components/alerts/SfAlertBase.vue'
import { mdiInformationOutline } from '@mdi/js'

export default defineComponent({
  name: 'SfAlertInfo',
  components: { SfAlertBase },
  setup() {
    return {
      mdiInformationOutline
    }
  }
})
</script>
