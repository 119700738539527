<template>
  <three-point-menu v-if="quote" dense>
    <v-list-item
      v-if="fullQuote && !isGuest"
      ref="archive-quote"
      :disabled="archiving"
      @click="toggleQuoteArchivation"
    >
      <v-list-item-title>
        {{ fullQuote.isArchived ? $t('common.dearchive') : $t('common.archive') }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item v-if="fullQuote" ref="print-quote" :disabled="printing" @click="printQuote">
      <v-list-item-title>
        {{ $t('print') }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="isQuoteInFinalStage(quote) && !isGuest"
      ref="request-again"
      @click="requestAgain"
    >
      <v-list-item-title>
        {{ $t('quoteRequest.requestAgain') }}
      </v-list-item-title>
    </v-list-item>
  </three-point-menu>
</template>

<script lang="ts">
import ThreePointMenu from '@/components/three-point-menu/ThreePointMenu.vue'
import { isGuest } from '@/helpers'
import { isQuoteInFinalStage } from '@/modules/quote/helpers'
import { QuoteItem, ShortQuoteRequestItem, QuoteStatus } from '@/generatedTypes'
import useQuote from '@/modules/quote/useQuote'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import QuoteEvent, { GuestQuoteEvent } from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'QuoteMenu',
  components: { ThreePointMenu },
  setup() {
    const { t } = useI18n()

    const { quote, printing, archiving } = storeToRefs(useQuote())
    const fullQuote = computed(() => quote.value?.quote)

    const toggleQuoteArchivation = () => {
      if (fullQuote.value) {
        useQuote().archiveQuote(fullQuote.value.id, !fullQuote.value.isArchived)
        trackEvent(fullQuote.value.isArchived ? QuoteEvent.Dearchive : QuoteEvent.Archive)
      }
    }

    const printQuote = () => {
      if (fullQuote.value) {
        useQuote().printQuote(fullQuote.value.id)

        if (isGuest.value) {
          trackEvent(GuestQuoteEvent.Print)
        } else {
          trackEvent(QuoteEvent.Download.Quote)
        }
      }
    }

    const requestAgain = () => {
      if (fullQuote.value) {
        const items = fullQuote.value.items.map((item: QuoteItem) => {
          const quoteRequestItemDto: ShortQuoteRequestItem = {
            productId: item.productId,
            label: item.productId ? undefined : item.productTitle,
            quantity: item.quantity
          }
          return quoteRequestItemDto
        })
        useQuoteRequest().createQuoteRequest(`${t('copy')}: ${fullQuote.value.name}`, items)
        trackEvent(QuoteEvent.RequestAgain)
      }
    }

    return {
      quote,
      fullQuote,
      printing,
      archiving,
      QuoteStatus,
      isGuest,
      isQuoteInFinalStage,
      requestAgain,
      toggleQuoteArchivation,
      printQuote
    }
  }
})
</script>
