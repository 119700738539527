import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/product/api'
import useProduct from '@/modules/product/useProduct'
import { RuntimeRequest } from '@/generatedTypes'
import useProductSearch from '@/modules/product/useProductSearch'

interface RealtimePricesState {
  loading: boolean
}

const initialState = (): RealtimePricesState => {
  return {
    loading: false
  }
}

const useRealtimePrices = defineStore('realtimePrices', {
  state: () => initialState(),
  actions: {
    async loadRealtimePrices(request: RuntimeRequest) {
      this.loading = true

      return api
        .loadRealtimePrices(request)
        .then(({ data }) => {
          useProduct().updateProductPrices(data)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'NONE'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    async loadRealtimePricesForSearch(request: RuntimeRequest) {
      this.loading = true

      return api
        .loadRealtimePrices(request)
        .then(({ data }) => {
          useProductSearch().updateProduct(data)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'NONE'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})

export default useRealtimePrices
