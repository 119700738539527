import {
  BundleItemProduct,
  Money,
  FullProduct,
  PreferedProductSearchView,
  SimpleStockStatus
} from '@/generatedTypes'
import { ProductIdentifier, ProductSort } from '@/modules/product/types'
import i18n from '@/plugins/i18n'
import { SelectOption, ViewToggle } from '@/types'
import { mdiViewList, mdiViewModule, mdiViewSplitVertical } from '@mdi/js'
import { Ref, computed } from 'vue'
import { RouteLocationNormalizedLoaded } from 'vue-router'

const formatMoney = (money?: Money, isResellPrice = false, showZeroPrice = false): string => {
  if ((money && money.value != 0) || showZeroPrice) {
    if (!money) {
      money = { value: 0, currency: 'EUR' }
    }
    return isResellPrice
      ? i18n.global.n(money.value, { key: 'currency', currency: money.currency }) +
          i18n.global.t('price.priceVatStar')
      : i18n.global.n(money.value, { key: 'currency', currency: money.currency })
  } else {
    return isResellPrice
      ? i18n.global.t('price.priceOnRequest').toString()
      : i18n.global.t('nullPriceLabel').toString()
  }
}

const formatPurchasePrice = (price?: number): string => {
  if (price && price > 0) {
    return i18n.global.n(price, 'currency')
  } else {
    return i18n.global.t('nullPriceLabel').toString()
  }
}

const formatDiscount = (price1: number, price2: number): string => {
  return i18n.global.n(price2 / price1 - 1, 'percentage')
}

const getStatusColor = (status: SimpleStockStatus): string => {
  switch (status) {
    case SimpleStockStatus.OnStock:
    case SimpleStockStatus.Available:
      return 'green'
    case SimpleStockStatus.Ordered:
    case SimpleStockStatus.Warehouse:
    case SimpleStockStatus.OnOrder:
      return 'orange'
    case SimpleStockStatus.NotAvailable:
    case SimpleStockStatus.Unknown:
    default:
      return 'red'
  }
}

const getProductIdentifiers = (
  product: FullProduct | BundleItemProduct,
  showProductIds: boolean
): ProductIdentifier[] => {
  const identifiers: ProductIdentifier[] = []
  if (product) {
    if (product.articleNumber.additionalId) {
      identifiers.push({
        name: 'additionalId',
        value: product.articleNumber.additionalId
      })
    }
    if (showProductIds) {
      if (product.articleNumber.manufacturerProductId) {
        identifiers.push({
          name: 'manufacturerId',
          value: product.articleNumber.manufacturerProductId
        })
      }
      if (product.articleNumber.ean) {
        identifiers.push({
          name: 'ean',
          value: product.articleNumber.ean
        })
      }
      if (product.productId) {
        identifiers.push({
          name: 'productId',
          value: product.productId.toString()
        })
      }
    }
  }
  return identifiers
}

const getProductSortOptions = (): SelectOption<ProductSort>[] => {
  return Object.values(ProductSort).map((option) => ({
    title: mapProductSortToText(option),
    value: option
  }))
}

const mapProductSortToText = (sort: ProductSort): string =>
  i18n.global.t('productSort.' + sort).toString()

const getProductViews = (showSuppliers: boolean): ViewToggle<PreferedProductSearchView>[] => [
  {
    name: PreferedProductSearchView.CARDS,
    icon: mdiViewModule,
    restriction: () => true,
    showOnMobile: false
  },
  {
    name: PreferedProductSearchView.LIST,
    icon: showSuppliers ? mdiViewSplitVertical : mdiViewList,
    restriction: () => true,
    showOnMobile: false
  }
]

const useProductRouterQuery = (
  route: RouteLocationNormalizedLoaded
): {
  keyword: Ref<string | undefined>
  sort: Ref<ProductSort | undefined>
  filters: Ref<string[]>
} => {
  const keyword = computed(() => route.query.keyword as string | undefined)
  const sort = computed(() => {
    const sortIdentifier = route.query.sort as string | undefined
    return Object.values(ProductSort).find((s) => s == sortIdentifier)
  })
  const filters = computed(() => {
    const filterTerm = route.query.filter as string | undefined
    if (!filterTerm) {
      return []
    }
    return filterTerm.split(',')
  })

  return { keyword, sort, filters }
}

export {
  formatDiscount,
  formatMoney,
  formatPurchasePrice,
  getProductIdentifiers,
  getProductSortOptions,
  getProductViews,
  getStatusColor,
  mapProductSortToText,
  useProductRouterQuery
}
