<template>
  <v-hover v-slot="{ isHovering, props }">
    <span
      ref="button"
      v-bind="props"
      class="d-flex align-center cursor-pointer font-weight-transition font-weight-regular text-body-1"
      :class="{ 'font-weight-medium': isHovering || active }"
    >
      <div ref="text" v-bind="$attrs">
        <span>
          <slot />
        </span>
        <v-icon v-if="isAsc" ref="icon-asc" :icon="mdiMenuUp" />
        <v-icon v-else ref="icon-desc" :icon="mdiMenuDown" />
      </div>
    </span>
  </v-hover>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { SortDirection } from '@/modules/order/types'
import { mdiMenuDown, mdiMenuUp } from '@mdi/js'

export default defineComponent({
  name: 'SortButton',
  props: {
    sort: {
      type: Number as PropType<SortDirection>,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isAsc = computed(() => props.sort === SortDirection.ASC)
    const isDesc = computed(() => props.sort === SortDirection.DESC)

    return { isAsc, isDesc, mdiMenuDown, mdiMenuUp }
  }
})
</script>

<style lang="scss" scoped>
.font-weight-transition {
  transition: font-weight 0.05s ease-out;
}
</style>
