import { ApiError, handleApiError } from '@/handleApiError'
import { isGuest, isQuotesPortal, parseFileName } from '@/helpers'
import {
  ContactData,
  ShopAddress,
  ContactUser,
  Quote,
  QuoteItemUpdate,
  QuoteStatus,
  QuoteUpdate
} from '@/generatedTypes'
import useAddress from '@/modules/address/useAddress'
import { LiveUpdateState } from '@/modules/eventStream/types'
import useEventStream from '@/modules/eventStream/useEventStream'
import helpers from '@/modules/message/helpers'
import useCheckout from '@/modules/order/useCheckout'
import useBranding from '@/modules/portalSettings/useBranding'
import api from '@/modules/quote/api'
import useQuoteActivity from '@/modules/quote/useQuoteActivity'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import useQuotes from '@/modules/quote/useQuotes'
import useGuestUser from '@/modules/user/useGuestUser'
import i18n from '@/plugins/i18n'
import router from '@/router'
import { UPDATE_TIMESTAMP } from '@/utils/timestampUtils'
import FileSaver from 'file-saver'
import { defineStore } from 'pinia'
import { Ref } from 'vue'

interface QuoteState extends LiveUpdateState {
  quote: Quote | undefined
  loading: boolean
  rejecting: boolean
  printing: boolean
  archiving: boolean
  ordering: boolean
}

const initialState = (): QuoteState => {
  return {
    quote: undefined,
    updateStream: undefined,
    loading: false,
    rejecting: false,
    printing: false,
    archiving: false,
    ordering: false
  }
}

const useQuote = defineStore('quote', {
  state: () => initialState(),
  getters: {
    getVersion: (state) => {
      return () => state.quote?.timestamp ?? ''
    }
  },
  actions: {
    async getQuote(id: string, backgroundLoading = false) {
      this.loading = !backgroundLoading

      return api
        .getQuote(id, isGuest.value)
        .then(({ data }) => {
          if (isGuest.value && data.quote != null) {
            ;(i18n.global.locale as unknown as Ref<string>).value = data.quote.alpha2language
          }
          this.quote = data
          useQuoteRequest().init(data.request)
          if (!isQuotesPortal.value) {
            useCheckout().cartOutdated = false
          }
          this.openQuoteUpdateStream(id, isGuest.value)
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              appearance: 'CUSTOM',
              customHandler: isQuotesPortal.value ? 'QuotesPortalLayout' : 'CategoryLayout'
            },
            {
              errorCode: 404,
              logError: false,
              customMessageKey: 'error.quoteNotFound'
            },
            {
              errorCode: 403,
              logError: false,
              customMessageKey: 'error.quoteForbidden'
            }
          )
        })
        .finally(() => {
          this.loading = false
          if (!isQuotesPortal.value) {
            useQuotes().getNewQuotesCount()
          }
        })
    },
    openQuoteUpdateStream(id: string, isGuest = false) {
      useEventStream.openStream(
        this.$state,
        api.getQuoteUpdateStreamPath(id, isGuest),
        (update: Quote) => (this.quote = update)
      )
    },
    closeQuoteUpdateStream() {
      useEventStream.closeStream(this.$state)
    },
    async printQuote(id: string) {
      this.printing = true

      return api
        .printQuote(id, isGuest.value)
        .then((response) => {
          FileSaver.saveAs(response.data, parseFileName(response.headers))
        })
        .catch(handleApiError)
        .finally(() => {
          this.printing = false
        })
    },
    async archiveQuote(quoteId: string, archive: boolean) {
      this.archiving = true

      if (archive) {
        return api
          .archiveQuote(quoteId)
          .then(() => {
            if (this.quote?.quote) {
              this.quote.quote.isArchived = true
            }
            helpers.reportSuccess('success.quoteArchived')
          })
          .catch(handleApiError)
          .finally(() => {
            this.archiving = false
          })
      } else {
        return api
          .dearchiveQuote(quoteId)
          .then(() => {
            if (this.quote?.quote) {
              this.quote.quote.isArchived = false
            }
            helpers.reportSuccess('success.quoteDearchived')
          })
          .catch(handleApiError)
          .finally(() => {
            this.archiving = false
          })
      }
    },
    async rejectQuote(id: string, comment: string) {
      this.rejecting = true

      return api
        .rejectQuote(id, { comment: comment }, isGuest.value)
        .then(() => {
          // reload quote for new data
          this.getQuote(id, true)
          if (isQuotesPortal.value) {
            useQuoteActivity().getActivityEntries(id, true)
          }
        })
        .catch(handleApiError)
        .finally(() => {
          this.rejecting = false
        })
    },
    async updateQuoteItemIsPickedOptional(id: string, isPickedOptional: boolean) {
      // Early out if there is no quote to update
      if (!this.quote?.quote) {
        return
      }
      const currentTimestamp = this.quote?.timestamp || ''

      const quoteId = this.quote.id
      const update: QuoteItemUpdate = {
        isPickedOptional,
        timestamp: currentTimestamp
      }
      return api
        .updateQuoteItem(quoteId, id, update, isGuest.value)
        .then(({ data }) => {
          this.getQuote(quoteId, true)
          UPDATE_TIMESTAMP(this.$state.quote, data)
        })
        .catch(handleApiError)
    },
    async updateQuoteItemQuantity(id: string, quantity: number) {
      // Early out if there is no quote to update
      if (!this.quote?.quote) {
        return
      }
      const currentTimestamp = this.quote?.timestamp || ''
      const quoteId = this.quote.id
      const update: QuoteItemUpdate = {
        quantity,
        timestamp: currentTimestamp
      }
      return api
        .updateQuoteItem(quoteId, id, update, isGuest.value)
        .then(({ data }) => {
          this.getQuote(quoteId, true)
          UPDATE_TIMESTAMP(this.$state.quote, data)
        })
        .catch(handleApiError)
    },
    async updateQuote(address?: ShopAddress, addressId?: string, contact?: ContactUser) {
      // Early out if there is no quote to update or all arguments are empty
      if (!this.quote?.quote || (!address && !addressId && !contact)) {
        return
      }

      const currentTimestamp = this.quote?.timestamp || ''
      const quoteId = this.quote.id
      const update: QuoteUpdate = {
        buyerAddress: address,
        buyerAddressId: addressId,
        buyerContact: contact as ContactData,
        timestamp: currentTimestamp
      }
      return api
        .updateQuote(quoteId, update, isGuest.value)
        .then(({ data }) => {
          this.getQuote(quoteId, true)
          UPDATE_TIMESTAMP(this.$state.quote, data)
          if (!isGuest.value && address) {
            useAddress().getAddresses()
          }
        })
        .catch(handleApiError)
    },
    async orderPlatformQuote(id: string) {
      this.ordering = true

      return api
        .orderPlatformQuote(id)
        .then(() => {
          useQuote().getQuote(id, true)
          if (isQuotesPortal.value) {
            useQuoteActivity().getActivityEntries(id, true)
          }
        })
        .catch(handleApiError)
        .finally(() => (this.ordering = false))
    },
    async orderPlatformQuoteAsGuest(id: string) {
      this.ordering = true
      const registrationEnabled = useBranding().quotesPortalRegistrationAllowed.value
      return api
        .orderGuestQuote(id)
        .then(() => {
          if (useGuestUser().guestUser.isRegistred) {
            router.push({
              name: 'Login',
              query: { quoteStatus: QuoteStatus.ACCEPTED }
            })
          } else if (registrationEnabled) {
            router.push({
              name: 'GuestRegistration',
              query: { quoteStatus: QuoteStatus.ACCEPTED }
            })
          } else {
            this.getQuote(id)
          }
        })
        .catch(handleApiError)
        .finally(() => (this.ordering = false))
    }
  }
})

export default useQuote
