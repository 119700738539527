<template>
  <v-alert class="text-body-1" density="compact" v-bind="$attrs" variant="tonal">
    <slot />
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfAlertBase'
})
</script>
