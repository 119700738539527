<template>
  <sf-delete-confirm-dialog
    :title="$t('quotes.deleteTitle')"
    :description="$t('quotes.deleteDescription')"
    @confirm="deleteQuoteRequest"
  >
    <template #activator="{ props }">
      <sf-text-button color="error" v-bind="props">
        {{ $t('delete') }}
      </sf-text-button>
    </template>
  </sf-delete-confirm-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfDeleteConfirmDialog from '@/components/dialog/SfDeleteConfirmDialog.vue'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import QuoteEvent from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DeleteQuoteRequestButton',
  components: { SfTextButton, SfDeleteConfirmDialog },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { deleting } = storeToRefs(useQuoteRequest())

    const deleteQuoteRequest = () => {
      trackEvent(QuoteEvent.DeleteRequest)
      useQuoteRequest().deleteQuoteRequest(props.id)
    }

    return {
      deleting,
      deleteQuoteRequest
    }
  }
})
</script>
