import { StorefrontLightTheme } from '@/configs/theme'
import { createVuetify } from 'vuetify'
import '@/assets/scss/main.scss'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

export default createVuetify({
  theme: {
    defaultTheme: 'StorefrontLightTheme',
    themes: {
      StorefrontLightTheme
    }
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  }
})
