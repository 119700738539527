<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12">
          <sf-heading ref="supplier-name">
            {{ supplierCart.supplierName || $t('noSupplier') }}
          </sf-heading>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <div :class="[{ 'drop--zone': canBeMovedToSupplierCart }]">
            <draggable
              :id="supplierCart.id"
              filter=".disable-move"
              handle=".handle"
              group="cart"
              chosen-class="border--primary"
              ghost-class="d-none"
              :list="supplierCart.cartItems"
              :sort="false"
              :disabled="loadingMoveInfo || $vuetify.display.mobile || draggableDisabled"
              item-key="id"
              @add="moveCartItem"
              @choose="setDraggingInfo"
              @unchoose="resetDraggingInfo"
            >
              <template #item="{ element }">
                <cart-row
                  :id="element.id"
                  :key="element.id"
                  :cart-item="element"
                  :loading="loading.remove"
                  :show-delete-button="!readOnly"
                  :show-stepper="!readOnly"
                  :max-length="supplierCart.maxPositionCommentLength"
                  :show-drag-handle="isDraggable && !$vuetify.display.mobile"
                  :validate-comments-immediately="validateCommentsImmediately"
                  @remove="removeProduct"
                  @change-quantity="changeQuantity"
                  @update-supplier-comment="updateSupplierComment"
                  @update-seller-comment="updateSellerComment"
                />
              </template>
            </draggable>
          </div>
        </v-col>
      </v-row>

      <cart-messages v-if="supplierCartMessages.length > 0" :cart-messages="supplierCartMessages" />

      <v-row dense>
        <v-col cols="12">
          <cart-info
            :cart-info="supplierCart.cartInfo"
            :gross-portal="portalSettings.grossPortal"
            :is-supplier-cart="true"
          >
            <template v-if="supplierCart.canOrder != CanOrder.FORBIDDEN && !readOnly" #button>
              <punchout-dialog
                v-if="isPunchoutMode"
                class="mt-1"
                :supplier-cart-id="supplierCart.id"
                :disabled="orderDisabled"
              />
              <cart-order-button
                v-else
                class="mt-1"
                :supplier-cart-id="supplierCart.id"
                :can-order="supplierCart.canOrder"
              />
            </template>
          </cart-info>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import SfHeading from '@/components/text/SfHeading.vue'
import useAuthentication from '@/modules/authentication/useAuthentication'
import CartInfo from '@/modules/cart/components/CartInfo.vue'
import CartMessages from '@/modules/cart/components/CartMessages.vue'
import CartOrderButton from '@/modules/cart/components/CartOrderButton.vue'
import CartRow from '@/modules/cart/components/CartRow.vue'
import { CanOrder, SupplierCart, SellerComments } from '@/generatedTypes'
import { CartMessageReason } from '@/modules/cart/types'
import useCart from '@/modules/cart/useCart'
import useCartItemMove from '@/modules/cart/useCartItemMove'
import useCheckout from '@/modules/order/useCheckout'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import PunchoutDialog from '@/modules/punchout/components/PunchoutDialog.vue'
import CartEvent from '@/modules/tracking/events/cart'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { SortableEvent } from 'sortablejs'
import { PropType, computed, defineComponent } from 'vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'SupplierCart',
  components: {
    CartInfo,
    CartMessages,
    CartRow,
    draggable,
    PunchoutDialog,
    SfHeading,
    CartOrderButton
  },
  props: {
    supplierCart: {
      type: Object as PropType<SupplierCart>,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    validateCommentsImmediately: {
      type: Boolean,
      default: false
    },
    updateCheckoutCartFn: {
      type: Function as PropType<(supplierCartId?: string) => void>,
      default: undefined
    }
  },
  setup(props) {
    const isPunchoutMode = useAuthentication().state.value.isPunchoutMode
    const { portalSettings } = storeToRefs(usePortalSettings())
    const { loading } = storeToRefs(useCart())
    const {
      availableSupplierCarts,
      draggingItem,
      draggingFrom,
      loading: loadingMoveInfo
    } = storeToRefs(useCartItemMove())

    const removeProduct = (id: string) => {
      useCart().removeCartItem(props.supplierCart.id, id)
      trackEvent(CartEvent.RemovePosition)
    }

    const changeQuantity = ({ id, quantity }: { id: string; quantity: number }) => {
      useCart().changeQuantity({ id, quantity })
    }

    const updateSupplierComment = ({ id, comment }: { id: string; comment: string }) => {
      useCart().updateSupplierComment(
        id,
        comment,
        props.supplierCart.timestamp,
        props.updateCheckoutCartFn
      )
      trackEvent(CartEvent.Comment.ToDistributor)
    }

    const updateSellerComment = ({
      id,
      comment,
      fieldIndex
    }: {
      id: string
      comment: string
      fieldIndex: keyof SellerComments
    }) => {
      useCart().updateSellerComment(
        id,
        comment,
        fieldIndex,
        props.supplierCart.timestamp,
        props.updateCheckoutCartFn
      )
      trackEvent(CartEvent.Comment.ToPortalOwner)
    }

    const moveCartItem = (event: SortableEvent) => {
      const toSupplierId = event.to.id
      const cartItemId = event.item.id

      useCartItemMove().moveCartItem(cartItemId, toSupplierId)
    }

    const setDraggingInfo = (event: SortableEvent) => {
      useCartItemMove().draggingItem = event.item.id
      useCartItemMove().draggingFrom = event.from.id
    }

    const resetDraggingInfo = () => {
      useCartItemMove().draggingItem = undefined
      useCartItemMove().draggingFrom = undefined
    }

    const canBeMovedToSupplierCart = computed(() => {
      const supplierCartId = props.supplierCart.id
      const draggingItemId = draggingItem.value

      if (draggingItemId) {
        const availableSupplierCartsList = availableSupplierCarts.value[draggingItemId]

        if (availableSupplierCartsList && availableSupplierCartsList.includes(supplierCartId)) {
          return true
        }
      }

      return false
    })

    const commentsInvalid = (supplierCart: SupplierCart) =>
      supplierCart.cartItems.some((cartItem) => !useCheckout().isCommentValid(cartItem.id))

    const draggableDisabled = computed(() => {
      return (
        !props.isDraggable ||
        // disable drop-zone when not allowing to move cart item to
        // the cart supplier except the one where dragging from
        (draggingItem.value != undefined &&
          !canBeMovedToSupplierCart.value &&
          draggingFrom.value != props.supplierCart.id)
      )
    })

    const supplierCartMessages = computed(() => {
      if (portalSettings.value.minimumOrderSurchargesOnlyApplyOncePerCart) {
        return props.supplierCart.messages.filter(
          (message) => message.reason === CartMessageReason.MinOrderValue
        )
      }
      return props.supplierCart.messages
    })

    const orderDisabled = computed(() => {
      return (
        (props.supplierCart && props.supplierCart.canOrder == CanOrder.DISABLED) ||
        commentsInvalid(props.supplierCart)
      )
    })

    return {
      loading,
      loadingMoveInfo,
      portalSettings,
      isPunchoutMode,
      draggableDisabled,
      canBeMovedToSupplierCart,
      removeProduct,
      changeQuantity,
      updateSupplierComment,
      updateSellerComment,
      moveCartItem,
      setDraggingInfo,
      resetDraggingInfo,
      orderDisabled,
      CanOrder,
      supplierCartMessages
    }
  }
})
</script>
