<template>
  <v-chip color="grey-darken-1" variant="outlined" size="x-small">
    {{ $t('productStockInfo.notRetail') }}
  </v-chip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfNonRetailChip'
})
</script>
