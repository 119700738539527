<template>
  <sf-alert-info v-if="error">
    {{ error === 'MAX_ITEMS' ? $t('cart.optimizationUnavailable') : $t('cart.optimizationFailed') }}
  </sf-alert-info>
  <v-row v-else dense justify="center">
    <template v-if="loading">
      <v-col
        v-for="index in 3"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        class="pa-2"
        style="height: 74px"
      >
        <v-skeleton-loader type="text" />
      </v-col>
    </template>
    <template v-else>
      <v-col
        v-for="optimization in availableOptimizations"
        :key="optimization.optimizationStrategy"
        cols="12"
        sm="6"
        :md="12 / availableOptimizations.length"
      >
        <v-card
          class="transparent-card pa-2 d-flex justify-center align-center"
          variant="outlined"
          height="100%"
          @click="optimize(optimization)"
        >
          <sf-text class="text-center">
            {{
              optimization.customName ||
              $t('optimizationStrategyType.' + optimization.optimizationStrategy)
            }}
          </sf-text>
          <v-progress-circular
            v-if="calculating"
            class="ml-2"
            indeterminate
            :size="24"
            color="primary"
          />
          <sf-color-text v-else class="ml-2" :color="getDiffColor(optimization)">
            {{ getDiffLabel(optimization) }}
          </sf-color-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script lang="ts">
import SfAlertInfo from '@/components/alerts/SfAlertInfo.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfText from '@/components/text/SfText.vue'
import { OptimizationStrategy, OptimizationType } from '@/generatedTypes'
import useCartOptimizations from '@/modules/cart/useCartOptimizations'
import { formatMoney } from '@/modules/product/helpers'
import CartEvent from '@/modules/tracking/events/cart'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CartOptimizations',
  components: { SfColorText, SfAlertInfo, SfText },
  setup() {
    useCartOptimizations().loadAvailableOptimizations()
    useCartOptimizations().calculateOptimizations()

    const { availableOptimizations, cartOptimizationResults, loading, calculating, error } =
      storeToRefs(useCartOptimizations())

    const getDiffLabel = (optimizationType: OptimizationType) => {
      const diff = cartOptimizationResults.value.find(
        (result) => result.optimizationStrategy === optimizationType.optimizationStrategy
      )?.difference

      if (diff != undefined) {
        const formattedDiff = formatMoney(diff, false, true)
        let prefix = ''
        if (diff.value === 0) {
          prefix = '±'
        } else if (diff.value > 0) {
          prefix = '+'
        }

        return prefix + formattedDiff
      } else {
        return ''
      }
    }

    const getDiffColor = (optimizationType: OptimizationType) => {
      const diff = cartOptimizationResults.value.find(
        (result) => result.optimizationStrategy === optimizationType.optimizationStrategy
      )?.difference

      if (diff != undefined) {
        if (diff.value < 0) {
          return 'green'
        } else if (diff.value > 0) {
          return 'red'
        }
      }

      return 'black'
    }

    const optimize = (optimization: OptimizationType) => {
      useCartOptimizations().optimizeCart(optimization.optimizationStrategy)
      let trackingEvent
      switch (optimization.optimizationStrategy) {
        case OptimizationStrategy.ADDITIONAL_SHOP_OPTIMIZATION:
          trackingEvent = CartEvent.Optimization.Additional
          break
        case OptimizationStrategy.MANUAL:
          trackingEvent = CartEvent.Optimization.Manual
          break
        case OptimizationStrategy.MINPRICE:
          trackingEvent = CartEvent.Optimization.MinPrice
          break
        case OptimizationStrategy.MINSUPPLIERS:
          trackingEvent = CartEvent.Optimization.MinimalSuppliers
          break
      }
      if (trackingEvent) {
        trackEvent(trackingEvent)
      }
    }

    return {
      availableOptimizations,
      cartOptimizationResults,
      loading,
      calculating,
      getDiffLabel,
      getDiffColor,
      optimize,
      error
    }
  }
})
</script>
