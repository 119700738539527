<template>
  <v-row id="pw-order-limit-default-settings" dense>
    <v-col cols="12" md="6">
      <v-card class="pa-1" height="100%">
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <sf-sub-heading>
                {{ $t('orderLimits.defaultLimit') }}
              </sf-sub-heading>

              <v-form ref="formRef" v-model="valid" class="mt-1">
                <sf-debounced-text-field
                  v-model="companyOrderLimit"
                  class="mb-1 input-text-right"
                  :disabled="!editable"
                  variant="outlined"
                  hide-details
                  suffix="€"
                  type="number"
                  hide-spin-buttons
                  min="0"
                  :rules="[requiredRule]"
                  autocomplete="off"
                  @keydown.enter.prevent
                  @debounce="companyOrderLimitChanged"
                />
              </v-form>
              <div class="text-justify">
                <sf-text>{{ $t('orderLimits.defaultLimitDescription') }}</sf-text>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card class="pa-1" height="100%">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" class="d-flex align-center">
              <sf-sub-heading>
                {{ $t('orderLimits.defaultApprovers') }}
              </sf-sub-heading>
              <v-spacer />
              <order-limit-default-approvers-dialog
                v-if="editable"
                ref="edit"
                :approvers="approvers"
                :default-approvers="defaultOrderLimit.defaultApprovers"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" class="d-flex flex-wrap align-center">
              <avatar-image
                v-for="approver in defaultOrderLimit.defaultApprovers"
                :key="approver.id"
                :user="approver"
                :size="40"
                class="mr-1 my-1"
              />
              <sf-text
                v-if="defaultOrderLimit.defaultApprovers.length === 0"
                ref="no-approver-selected"
              >
                {{ $t('orderLimits.defaultApproversEmpty') }}
              </sf-text>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfDebouncedTextField from '@/components/input/SfDebouncedTextField.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { fitCropImage, getFullName } from '@/helpers'
import OrderLimitDefaultApproversDialog from '@/modules/orderLimits/components/OrderLimitDefaultApproversDialog.vue'
import useOrderLimits from '@/modules/orderLimits/useOrderLimits'
import MyCompanyEvent from '@/modules/tracking/events/MyCompany'
import { trackEvent } from '@/modules/tracking/useTracking'
import { requiredRule } from '@/rules'
import { VForm } from '@/types'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'OrderLimitDefaultSettings',
  components: {
    OrderLimitDefaultApproversDialog,
    AvatarImage,
    SfText,
    SfSubHeading,
    SfDebouncedTextField
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const valid = ref(false)
    const formRef = ref<VForm>()

    const store = useOrderLimits()
    const { defaultOrderLimit, approvers } = storeToRefs(store)

    const companyOrderLimit = computed({
      get() {
        return defaultOrderLimit.value.companyOrderLimit
      },
      set(value: number | undefined) {
        if (value) {
          useOrderLimits().$state.defaultOrderLimit = {
            ...useOrderLimits().$state.defaultOrderLimit,
            companyOrderLimit: Math.abs(value)
          }
        }
      }
    })

    const companyOrderLimitChanged = () => {
      trackEvent(MyCompanyEvent.OrderLimit.Default.Limit)
      store.updateDefaultOrderLimit({
        defaultOrderLimit: companyOrderLimit.value
      })
    }

    onMounted(() => {
      formRef.value?.validate()
    })

    return {
      getFullName,
      fitCropImage,
      requiredRule,
      defaultOrderLimit,
      companyOrderLimit,
      approvers,
      valid,
      formRef,
      companyOrderLimitChanged
    }
  }
})
</script>
