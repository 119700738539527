enum Step {
  CONTACT = 'contact',
  COMPANY = 'company',
  PASSWORD = 'password',
  REQUESTED = 'requested'
}

type LoginCredentials = {
  mail: string
  password: string
}

enum LoginError {
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  USER_DEACTIVATED = 'USER_DEACTIVATED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NONE = 'NONE'
}

export { LoginCredentials, LoginError, Step }
