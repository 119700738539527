<template>
  <v-dialog v-model="dialog" :width="500" attach>
    <template v-if="guestQuote" #activator="{ props }">
      <sf-text-button
        ref="declineButton"
        v-bind="props"
        color=""
        class="text-medium-emphasis mr-1"
        :loading="rejecting"
        :disabled="disabled"
      >
        {{ $t('decline') }}
      </sf-text-button>
    </template>
    <template v-else #activator="{ props }">
      <sf-large-button
        ref="declineButton"
        text
        v-bind="props"
        color=""
        variant="text"
        class="text-medium-emphasis"
        :block="$vuetify.display.xs"
        :loading="rejecting"
        :disabled="disabled"
      >
        {{ $t('decline') }}
      </sf-large-button>
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading ref="title">{{ $t(titleLabel) }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text v-if="type === 'QUOTE'">{{ $t('quotes.declineQuoteDialog') }}</v-card-text>
      <v-card-text>
        <span>
          <v-textarea
            ref="rejectionCommentArea"
            v-model="rejectionCommentValue"
            :label="$t('declineReason')"
            clearable
            auto-grow
            density="compact"
            hide-details="auto"
            variant="outlined"
            color="primary"
            :maxlength="DEFAULT_COMMENT_LENGTH"
            autocomplete="off"
            :hint="
              rejectionCommentValue && rejectionCommentValue.length >= DEFAULT_COMMENT_LENGTH
                ? $t('maxLength', [DEFAULT_COMMENT_LENGTH])
                : ''
            "
            persistent-hint
          />
        </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="dialog = false">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button color="error" @click="onClickReject">
          {{ $t('decline') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { CommentType, DEFAULT_COMMENT_LENGTH } from '@/types'
import { computed, defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'RejectionDialog',
  components: { SfLargeButton, SfTextButton, SfHeading },
  props: {
    rejecting: {
      type: Boolean,
      required: true
    },
    rejectionComment: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<CommentType>,
      required: true
    },
    guestQuote: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['reject'],
  setup(props, { emit }) {
    const dialog = ref(false)
    const rejectionCommentValue = ref<string | undefined>(props.rejectionComment)

    const titleLabel = computed(() => {
      switch (props.type) {
        case 'ORDER':
          return 'order.declineOrder'
        case 'QUOTE':
          return 'quotes.declineQuote'
        default:
          return 'undefined'
      }
    })

    const onClickReject = () => {
      emit('reject', rejectionCommentValue.value)
      dialog.value = false
    }

    return {
      dialog,
      titleLabel,
      onClickReject,
      rejectionCommentValue,
      DEFAULT_COMMENT_LENGTH
    }
  }
})
</script>
