<template>
  <v-row>
    <v-col cols>
      <list-header :title="$t('quotes.quotes').toString()">
        <template #search>
          <search-bar :value="keyword" @search="setKeyword" @clear="resetSearch" />
        </template>
        <template #filter>
          <filter-button :active-filters="activeFilters.length" @toggle="toggleFilter" />
        </template>
        <template #action>
          <sf-create-dialog
            :title="$t('quotes.createRequest').toString()"
            :loading="creating"
            @create="createQuoteRequest"
          />
        </template>
        <template #cols>
          <v-col class="d-flex align-center justify-start" cols="">
            <sf-text>{{ $t('title') }}</sf-text>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="2">
            <sort-button :sort="direction" @click="toggleSort(page, keyword)">
              {{ $t('createdDate') }}
            </sort-button>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="2">
            <sf-text>{{ $t('costs') }}</sf-text>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="2">
            <sf-text>{{ $t('status') }}</sf-text>
          </v-col>
        </template>
      </list-header>
      <error-component
        v-if="mustHandleError"
        :message="error.message"
        :code="error.code"
        :transparent-background="true"
      />
      <list-loading v-else-if="loading" />
      <template v-else-if="quotes.length === 0">
        <list-empty :message="$t('quotes.noQuotesFound').toString()" :icon="mdiHandshake" />
        <create-request-hint v-if="!mustHandleError" />
      </template>
      <list-content v-else>
        <v-col cols="12">
          <quote-row v-for="quote in quotes" :key="quote.id" :quote="quote" />
        </v-col>
        <v-col v-if="pages > 1" cols="12">
          <v-pagination
            :length="pages"
            :model-value="page"
            rounded
            total-visible="5"
            variant="flat"
            density="comfortable"
            active-color="primary"
            @update:model-value="goToPage"
          />
        </v-col>
      </list-content>
    </v-col>

    <mobile-side-menu v-if="$vuetify.display.mobile" :show="showFilter" @close="toggleFilter()">
      <quote-filter @close="toggleFilter()" @update="searchQuotes" />
    </mobile-side-menu>

    <v-col v-if="!$vuetify.display.mobile" v-show="showFilter" cols="3">
      <quote-filter @close="toggleFilter()" @update="searchQuotes" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import MobileSideMenu from '@/components/MobileSideMenu.vue'
import SearchBar from '@/components/SearchBar.vue'
import SortButton from '@/components/SortButton.vue'
import SfCreateDialog from '@/components/dialog/SfCreateDialog.vue'
import FilterButton from '@/components/filter/FilterButton.vue'
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import ListLoading from '@/components/list/ListLoading.vue'
import SfText from '@/components/text/SfText.vue'
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { customErrorHandling } from '@/modules/error/helpers'
import CreateRequestHint from '@/modules/quote/components/CreateRequestHint.vue'
import QuoteFilter from '@/modules/quote/components/QuoteFilter.vue'
import QuoteRow from '@/modules/quote/components/QuoteRow.vue'
import { useQuoteSortToggle } from '@/modules/quote/helpers'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import useQuotes from '@/modules/quote/useQuotes'
import useQuotesFilter from '@/modules/quote/useQuotesFilter'
import QuoteEvent from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import router from '@/router'
import { useTitle } from '@/helpers'
import { useToggle } from '@vueuse/shared'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { mdiHandshake } from '@mdi/js'

export default defineComponent({
  name: 'QuotesPage',
  components: {
    SearchBar,
    SfCreateDialog,
    ListContent,
    ListEmpty,
    ListHeader,
    ListLoading,
    QuoteRow,
    SfText,
    CreateRequestHint,
    FilterButton,
    QuoteFilter,
    SortButton,
    MobileSideMenu,
    ErrorComponent
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('quotes.quotes')}`))
    const { error, mustHandleError } = customErrorHandling()
    const currentRoute = useRoute()
    const { initialized } = storeToRefs(useQuotesFilter())
    const [showFilter, toggleFilter] = useToggle()
    const { direction, toggleSort } = useQuoteSortToggle()
    const { quotes, pages, loading } = storeToRefs(useQuotes())
    const { creating } = storeToRefs(useQuoteRequest())
    const activeFilters = computed(() => useQuotesFilter().getActiveFilters())

    const page = computed(() => {
      return parseInt(currentRoute.query.page as string) || 1
    })

    const keyword = computed(() => {
      return currentRoute.query.keyword as string
    })

    const goToPage = (newPage: number) => {
      if (page.value !== newPage) {
        router.push({
          name: 'Quotes',
          query: { ...currentRoute.query, page: newPage.toString() }
        })
      }
    }

    if (initialized.value) {
      useQuotes().getQuotes(page.value, keyword.value)
    } else {
      useQuotes().initQuotes(page.value, keyword.value)
    }

    const setKeyword = (keyword: string) => {
      trackEvent(QuoteEvent.TextSearch)
      router.push({
        name: 'Quotes',
        query: {
          keyword
        }
      })
    }

    const resetSearch = () => {
      router.push({
        name: 'Quotes'
      })
    }

    watch(currentRoute, () => {
      useQuotes().getQuotes(page.value, keyword.value)
    })

    const searchQuotes = () => {
      useQuotes().getQuotes(page.value, keyword.value)
    }

    const createQuoteRequest = (name: string) => {
      useQuoteRequest().createQuoteRequest(name)
      trackEvent(QuoteEvent.CreateRequest)
    }

    return {
      error,
      mustHandleError,
      quotes,
      loading,
      creating,
      direction,
      showFilter,
      activeFilters,
      keyword,
      page,
      pages,
      createQuoteRequest,
      toggleSort,
      toggleFilter,
      searchQuotes,
      setKeyword,
      resetSearch,
      goToPage,
      mdiHandshake
    }
  }
})
</script>
