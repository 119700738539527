<template>
  <sf-info-dialog :title="$t('price.graduatedPrices')">
    <template #activator="{ props }">
      <sf-text-button v-bind="props">
        <v-icon start :icon="mdiElevationDecline" />
        {{ $t('price.graduatedPrices') }}
      </sf-text-button>
    </template>
    <template #content>
      <div
        v-for="(supplier, index) in suppliersWithScales"
        :key="supplier.id"
        :class="{ 'mb-2': index != suppliersWithScales.length - 1 }"
      >
        <div class="d-flex justify-space-between flex-gap">
          <sf-sub-heading>
            {{ supplier.supplierName }}
          </sf-sub-heading>
          <sf-color-text v-if="supplier.stock" :color="getStatusColor(supplier.stock.status)">
            {{ supplier.stock.count > 0 ? supplier.stock.count : '' }}
            {{ $t('stockStatus.' + supplier.stock.status) }}
          </sf-color-text>
        </div>
        <div
          v-for="scale in supplier.scales"
          :key="scale.minScale"
          class="d-flex justify-space-between flex-gap"
        >
          <sf-text>
            {{ $t('price.startingAt', [scale.minScale]) }}
          </sf-text>
          <sf-text>{{ formatMoney(scale.resellPrice, true) }}</sf-text>
        </div>
      </div>
    </template>
  </sf-info-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfInfoDialog from '@/components/dialog/SfInfoDialog.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { computed, defineComponent, PropType, ref } from 'vue'
import { formatMoney, getStatusColor } from '@/modules/product/helpers'
import { SupplierRow } from '@/generatedTypes'
import { mdiElevationDecline } from '@mdi/js'

export default defineComponent({
  name: 'ProductGraduation',
  components: { SfInfoDialog, SfTextButton, SfSubHeading, SfColorText, SfText },
  props: {
    suppliers: {
      type: Array as PropType<SupplierRow[]>,
      required: true
    }
  },
  setup(props) {
    const dialog = ref(false)
    const suppliersWithScales = computed(() => {
      return props.suppliers.filter((supplier) => supplier.scales.length > 0)
    })

    return {
      dialog,
      suppliersWithScales,
      formatMoney,
      getStatusColor,
      mdiElevationDecline
    }
  }
})
</script>
