import { ApiError, handleApiError } from '@/handleApiError'
import useAddressValidation from '@/modules/address/useAddressValidation'
import useCartCount from '@/modules/cart/useCartCount'
import helpers from '@/modules/message/helpers'
import api from '@/modules/order/api'
import { DetailedOrder, UpdateOrderItemDTO, UpdateOrderPatchDTO } from '@/generatedTypes'
import { AddToCartSuccess, OrdersView } from '@/modules/order/types'
import useCheckout from '@/modules/order/useCheckout'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import useSubsidiary from '@/modules/subsidiary/useSubsidiary'
import useOwnUser from '@/modules/user/useOwnUser'
import router from '@/router'
import { KeyValue } from '@/types'
import { UPDATE_TIMESTAMP } from '@/utils/timestampUtils'
import { defineStore, storeToRefs } from 'pinia'

interface OrderState {
  order: DetailedOrder | undefined
  loading: boolean
  sending: boolean
  deletingOrderItem: boolean
  rejecting: boolean
  orderOutdated: boolean
  commentsValid: KeyValue[]
}

const initialState = (): OrderState => {
  return {
    order: undefined,
    loading: false,
    sending: false,
    rejecting: false,
    deletingOrderItem: false,
    orderOutdated: false,
    commentsValid: []
  }
}

const reloadOrRedirect = (id: string) => {
  const { portalSettings } = storeToRefs(usePortalSettings())
  const { isAdmin } = storeToRefs(useOwnUser())

  if (isAdmin.value() || !portalSettings.value.isUserSpecificCartEnabled) {
    useOrder().getOrder(id)
  } else {
    router.push({ name: 'Orders', query: { view: OrdersView.APPROVALS } })
  }
}

const useOrder = defineStore('order', {
  state: () => initialState(),
  getters: {
    allCommentsValid(state) {
      return state.commentsValid.every((entry) => {
        return entry.value
      })
    }
  },
  actions: {
    addCommentValid(id: string, valid: boolean) {
      const entry = this.commentsValid.find((entry) => entry.key === id)
      if (entry) {
        entry.value = valid
      } else {
        this.commentsValid.push({ key: id, value: valid })
      }
    },
    async getOrder(id: string, background = false) {
      this.loading = !background
      if (!background) {
        this.commentsValid = []
      }
      useCheckout().orderNumberError = undefined

      return api
        .getOrder(id)
        .then(({ data }) => {
          this.order = data
          this.orderOutdated = false
          useAddressValidation().$reset()
        })
        .catch((error: ApiError) => {
          if (error.code == 412 && error.axiosError?.response?.data.detail) {
            useSubsidiary().switchToSubsidiary(
              error.axiosError.response.data.detail,
              router.resolve({ name: 'OrderDetails', params: { id } }).href
            )
          } else {
            handleApiError(
              error,
              {
                appearance: 'CUSTOM',
                customHandler: 'CategoryLayout'
              },
              {
                errorCode: 403,
                logError: false,
                customMessageKey: 'order.orderNotFound'
              },
              {
                errorCode: 404,
                logError: false,
                customMessageKey: 'order.orderNotFound'
              }
            )
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async sendExistingOrder(id: string) {
      this.sending = true
      const currentTimestamp = this.order?.timestamp || ''

      return api
        .sendExistingOrder(id, currentTimestamp)
        .then(() => {
          helpers.reportSuccess('order.orderSent')
          reloadOrRedirect(id)
        })
        .catch((error: ApiError) => {
          if (error.code == 409) {
            this.orderOutdated = true
          } else {
            handleApiError(error, undefined, {
              errorCode: 403,
              logError: false
            })
          }
        })
        .finally(() => {
          this.sending = false
        })
    },
    async sendExistingOrderAndPay(id: string) {
      this.sending = true
      const currentTimestamp = this.order?.timestamp || ''

      return api
        .sendExistingOrderAndPay(id, currentTimestamp)
        .then(({ data }) => {
          if (data) {
            window.location.href = data
          }
        })
        .catch((error: ApiError) => {
          if (error.code == 409) {
            this.orderOutdated = true
          } else {
            handleApiError(
              error,
              undefined,
              {
                // connection failure to sofort
                errorCode: 502,
                customMessageKey: 'order.paymentServiceConnectionException'
              },
              {
                errorCode: 403,
                logError: false
              }
            )
          }
        })
        .finally(() => {
          this.sending = false
        })
    },
    async requestExistingOrder(id: string, comment: string) {
      this.sending = true

      return api
        .requestExistingOrder(id, { commentToApprovers: comment })
        .then(() => {
          helpers.reportSuccess('order.orderRequested')
          reloadOrRedirect(id)
        })
        .catch(handleApiError)
        .finally(() => {
          this.sending = false
        })
    },
    async declineOrder(id: string, comment: string) {
      this.rejecting = true

      return api
        .declineOrder(id, { comment: comment })
        .then(() => {
          helpers.reportSuccess('orderApproval.orderDeclined')
          reloadOrRedirect(id)
        })
        .catch(handleApiError)
        .finally(() => {
          this.rejecting = false
        })
    },
    async archiveOrder(orderId: string, isArchived: boolean) {
      const currentTimestamp = this.order?.timestamp || ''

      return api
        .updateOrder(orderId, { timestamp: currentTimestamp, isArchived })
        .then(({ data }) => {
          if (this.order) {
            this.order.isArchived = isArchived
          }
          if (isArchived) {
            helpers.reportSuccess('success.orderArchived')
          } else {
            helpers.reportSuccess('success.orderDearchived')
          }

          UPDATE_TIMESTAMP(this.$state.order, data)
        })
        .catch(handleApiError)
    },
    async updateOrder(orderId: string, dto: UpdateOrderPatchDTO) {
      return api
        .updateOrder(orderId, dto)
        .then(({ data }) => {
          UPDATE_TIMESTAMP(this.$state.order, data)
        })
        .catch(handleApiError)
        .finally(() => {
          this.getOrder(orderId, true)
        })
    },
    async deleteOrder(orderId: string) {
      return api
        .deleteOrder(orderId)
        .then(() => {
          router.push({ name: 'Orders' })
        })
        .catch(handleApiError)
    },
    async updateOrderItem(orderId: string, orderItemId: string, dto: Partial<UpdateOrderItemDTO>) {
      const currentTimestamp = this.order?.timestamp || ''
      if (!dto.timestamp) {
        dto = { ...dto, timestamp: currentTimestamp }
      }
      return api
        .updateOrderItem(orderId, orderItemId, dto as UpdateOrderItemDTO)
        .then(({ data }) => {
          UPDATE_TIMESTAMP(this.$state.order, data)
        })
        .catch(handleApiError)
        .finally(() => {
          this.getOrder(orderId, true)
        })
    },
    async removeOrderItem(orderId: string, orderItemId: string) {
      this.deletingOrderItem = true

      this.order?.items.splice(
        this.order?.items.findIndex((item) => {
          return item.id === orderId
        }),
        1
      )

      return api
        .removeOrderItem(orderId, orderItemId)
        .catch(handleApiError)
        .finally(() => {
          this.deletingOrderItem = false
          this.getOrder(orderId, true)
        })
    },
    async removeLastOrderItem(orderId: string, orderItemId: string) {
      this.deletingOrderItem = true

      return api
        .removeOrderItem(orderId, orderItemId)
        .then(() => {
          router.push({ name: 'Orders' })
        })
        .catch(handleApiError)
        .finally(() => {
          this.deletingOrderItem = false
        })
    },
    async addToCart(orderId: string) {
      return api
        .addToCart(orderId)
        .then(({ data }) => {
          switch (data) {
            case AddToCartSuccess.SUCCESS:
              helpers.reportSuccess('order.addedToCartSuccess')
              break
            case AddToCartSuccess.PARTIAL_SUCCESS:
              helpers.reportWarning('order.addedToCartPartialSuccess')
              break
            case AddToCartSuccess.FAILURE:
              helpers.reportError('order.addedToCartFailure')
              break
          }
        })
        .catch(handleApiError)
        .finally(() => {
          useCartCount().loadCartCount()
        })
    }
  }
})

export default useOrder
