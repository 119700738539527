import LoginLayout from '@/layouts/LoginLayout.vue'
import QuotesPortalLayout from '@/layouts/QuotesPortalLayout.vue'
import AddressesPage from '@/pages/AddressesPage.vue'
import CompanyPage from '@/pages/CompanyPage.vue'
import ErrorPage from '@/pages/ErrorPage.vue'
import GuestRegistrationPage from '@/pages/GuestRegistrationPage.vue'
import LoginPage from '@/pages/LoginPage.vue'
import LogoutPage from '@/pages/LogoutPage.vue'
import OrderLimitPage from '@/pages/OrderLimitPage.vue'
import QuotePage from '@/pages/QuotePage.vue'
import QuotesPage from '@/pages/QuotesPage.vue'
import ResetPasswordPage from '@/pages/ResetPasswordPage.vue'
import ShortUrlPage from '@/pages/ShortUrlPage.vue'
import UserPage from '@/pages/UserPage.vue'
import UsersPage from '@/pages/UsersPage.vue'
import { RouteRecordRaw } from 'vue-router'

export const quotesPortalRoutes: RouteRecordRaw[] = [
  {
    path: '/s/:id',
    component: ShortUrlPage
  },
  {
    path: '/logout',
    component: LogoutPage
  },
  {
    path: '/login',
    component: LoginLayout,
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: LoginPage
      },
      {
        path: 'reset/:token',
        name: 'ResetPassword',
        component: ResetPasswordPage
      }
    ]
  },
  {
    path: '/guest',
    name: 'Guest',
    component: QuotesPortalLayout,
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: 'quotes/:id',
        name: 'GuestQuote',
        component: QuotePage
      },
      {
        path: 'registration',
        name: 'GuestRegistration',
        component: GuestRegistrationPage
      }
    ]
  },
  {
    path: '/',
    component: QuotesPortalLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'StartPage',
        component: QuotesPage
      },
      {
        path: 'quotes',
        name: 'Quotes',
        component: QuotesPage
      },
      {
        path: 'quotes/:id',
        name: 'Quote',
        component: QuotePage
      },
      {
        path: 'company',
        name: 'Company',
        redirect: '/company/employees',
        component: CompanyPage,
        children: [
          {
            path: 'employees',
            name: 'CompanyEmployees',
            component: UsersPage
          },
          {
            path: 'employees/:id',
            name: 'CompanyEmployee',
            component: UserPage
          },
          {
            path: 'addresses',
            name: 'CompanyAddresses',
            component: AddressesPage
          },
          {
            path: 'order-limit',
            name: 'CompanyOrderLimit',
            component: OrderLimitPage
          }
        ]
      },
      {
        path: '*',
        name: 'Error',
        component: ErrorPage
      }
    ]
  }
]
