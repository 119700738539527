import { concat } from 'lodash-es'
import api from '@/modules/product/api'
import { MediumProduct, ProductPriceUpdate } from '@/generatedTypes'
import { ProductSearchOptions } from '@/modules/product/types'
import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import { PAGE_SIZE } from '@/types'

interface ProductSearchState {
  loading: number
  items: MediumProduct[]
  count: number
  pages: number
  options: ProductSearchOptions | undefined
  loadingNextPage: boolean
  keywordTooShortError: boolean
}

const initialState = (): ProductSearchState => {
  return {
    loading: 0,
    items: [],
    pages: 0,
    count: 0,
    options: undefined,
    loadingNextPage: false,
    keywordTooShortError: false
  }
}

const useProductSearch = defineStore('productSearch', {
  state: () => initialState(),
  actions: {
    async searchProducts(options: ProductSearchOptions) {
      if (!options.keyword || options.keyword.length > 1) {
        this.loading++
        this.keywordTooShortError = false
        this.options = options

        return api
          .searchProducts(options)
          .then(({ data }) => {
            this.items = data.items
            this.pages = data.pageCount
            this.count = data.totalCount
          })
          .catch((error: ApiError) => {
            this.items = []
            this.pages = 0
            this.count = 0
            handleApiError(error)
          })
          .finally(() => {
            this.loading--
          })
      } else {
        this.keywordTooShortError = true
        this.items = []
        this.pages = 0
        this.count = 0
      }
    },
    async loadNextPage() {
      if (this.options) {
        if (!this.options.page) {
          this.options.page = 2
        } else {
          this.options.page += 1
        }

        if (
          this.options.page <= this.pages &&
          this.items.length === PAGE_SIZE * (this.options.page - 1)
        ) {
          this.loadingNextPage = true

          return api
            .searchProducts(this.options)
            .then(({ data }) => {
              this.items = concat(this.items, data.items)
              this.pages = data.pageCount
              this.count = data.totalCount
            })
            .catch((error: ApiError) => {
              this.items = []
              this.pages = 0
              this.count = 0
              handleApiError(error)
            })
            .finally(() => {
              this.loadingNextPage = false
            })
        }
      }
    },
    updateProduct(update: ProductPriceUpdate) {
      const index = this.items.findIndex((item) => item.productId === update.productId)
      if (index >= 0) {
        this.items[index].price = update.price
        this.items[index].stock = update.stock
        this.items[index].suppliers = update.suppliers
      }
    }
  }
})

export default useProductSearch
