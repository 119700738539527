<template>
  <div>
    {{ $t('registrationRequested') }}
    <div class="text-center mt-2 text-body-2 text-primary">
      <div class="d-inline cursor-pointer" @click="toLogin">
        {{ $t('login') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from '@/router'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelfRegistrationRequested',
  setup() {
    function toLogin() {
      router.replace('/login')
    }
    return {
      toLogin
    }
  }
})
</script>
