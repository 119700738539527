<template>
  <div class="d-flex align-center">
    <v-text-field
      v-if="editing"
      ref="textFieldRef"
      v-model="textFieldModel"
      v-click-outside="finishEditing"
      :rules="[requiredRule]"
      hide-details
      variant="underlined"
      color="primary"
      density="compact"
      class="pt-0 mt-0 editable-heading-input"
      autocomplete="off"
      @keydown.enter="finishEditing"
      @keydown.esc="cancelEditing"
    />
    <template v-if="!editing">
      <sf-title class="mr-1" :class="[{ 'text-truncate': !$vuetify.display.mobile }]">
        {{ value }}
      </sf-title>
      <sf-edit-button @click="enableEditing" />
    </template>
  </div>
</template>

<script lang="ts">
import SfEditButton from '@/components/button/SfEditButton.vue'
import SfTitle from '@/components/text/SfTitle.vue'
import { requiredRule } from '@/rules'
import { defineComponent, nextTick, ref } from 'vue'

export default defineComponent({
  name: 'EditableHeading',
  components: { SfEditButton, SfTitle },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const editing = ref(false)
    const textFieldRef = ref<InstanceType<typeof HTMLInputElement> | null>(null)
    const textFieldModel = ref<string>('')

    const enableEditing = () => {
      editing.value = true
      textFieldModel.value = props.value
      nextTick(() => {
        setTimeout(() => {
          textFieldRef.value?.focus()
        })
      })
    }

    const cancelEditing = () => {
      editing.value = false
    }

    const finishEditing = () => {
      editing.value = false
      if (textFieldModel.value) {
        emit('input', textFieldModel.value)
      }
    }

    return {
      cancelEditing,
      editing,
      emit,
      enableEditing,
      finishEditing,
      requiredRule,
      textFieldModel,
      textFieldRef
    }
  }
})
</script>

<style lang="scss">
.editable-heading-input input {
  min-width: 300px;
  font-size: 24px !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
}
</style>
