<template>
  <login-wrapper v-if="branding" :branding="branding" :loading="loading" :title="$t(title)">
    <div class="mt-2">
      <self-registration-form v-if="step == Step.CONTACT" @submit="handleContactSubmit" />
      <self-registration-company v-if="step == Step.COMPANY" @submit="handleCompanySubmit" />
      <self-registration-password v-if="step == Step.PASSWORD" @submit="handlePasswordSubmit" />
      <self-registration-requested v-if="step == Step.REQUESTED" />
    </div>
  </login-wrapper>
</template>

<script lang="ts">
import LoginWrapper from '@/modules/authentication/components/LoginWrapper.vue'
import SelfRegistrationCompany from '@/modules/authentication/components/SelfRegistrationCompany.vue'
import SelfRegistrationForm from '@/modules/authentication/components/SelfRegistrationForm.vue'
import SelfRegistrationPassword from '@/modules/authentication/components/SelfRegistrationPassword.vue'
import SelfRegistrationRequested from '@/modules/authentication/components/SelfRegistrationRequested.vue'
import { Company, Contact, SelfRegistration, Title } from '@/generatedTypes'
import { Step } from '@/modules/authentication/types'
import useSelfRegistration from '@/modules/authentication/useSelfRegistration'
import useBranding from '@/modules/portalSettings/useBranding'
import router from '@/router'
import { useStateManager } from '@/store'
import { useTitle } from '@/helpers'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onBeforeMount, reactive, ref, toRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'SelfRegistrationPage',
  components: {
    SelfRegistrationForm,
    SelfRegistrationCompany,
    SelfRegistrationPassword,
    SelfRegistrationRequested,
    LoginWrapper
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('windowTitle.Registration')}`))
    const { branding } = useBranding()
    const { loading } = storeToRefs(useSelfRegistration())
    useStateManager().reset()

    const step = ref(Step.CONTACT)

    // user should be able to go back to CONTACT step but he must not go forward to PASSWORD/COMPANY step
    const route = toRef(reactive(router), 'currentRoute')
    watch(
      () => route.value.params.step,
      (nextStep) => {
        if (nextStep === Step.CONTACT) {
          step.value = Step.CONTACT
        } else if (step.value !== nextStep) {
          resetSelfRegistration()
        }
      }
    )

    const title = computed(() => {
      switch (step.value) {
        case Step.CONTACT: {
          return 'registration'
        }
        case Step.COMPANY: {
          return 'registerCompany'
        }
        case Step.PASSWORD: {
          return 'setPassword'
        }
        case Step.REQUESTED: {
          return 'registerCompany'
        }
        default: {
          return ''
        }
      }
    })

    // if user reloads when he is not at CONTACT step, he will be redirected to CONTACT step
    onBeforeMount(() => {
      const nextStep = router.currentRoute.value.params.step
      if (nextStep !== Step.CONTACT) {
        resetSelfRegistration()
      }
    })

    const selfRegistration: SelfRegistration = {
      person: {
        firstName: '',
        lastName: '',
        alpha2language: navigator.language,
        email: '',
        contactTitle: Title.Unspecified
      },
      password: '',
      termsConfirmation: false,
      customTermsConfirmation: false
    }

    function toLogin() {
      router.replace('/login')
    }

    function resetSelfRegistration() {
      const routerStep = router.currentRoute.value.params.step
      if (routerStep !== Step.CONTACT) {
        router.replace({
          name: 'SelfRegistration',
          params: { step: Step.CONTACT }
        })
      } else {
        step.value = Step.CONTACT
      }
    }

    function handleContactSubmit({
      contact,
      consent,
      step: nextStep
    }: {
      contact: Contact
      consent: { consent: boolean; customTermConsent: boolean }
      step: Step
    }) {
      selfRegistration.person = contact
      selfRegistration.termsConfirmation = consent.consent
      selfRegistration.customTermsConfirmation = consent.customTermConsent
      router.push({ name: 'SelfRegistration', params: { step: nextStep } })
      step.value = nextStep
    }

    function handleCompanySubmit(company: Company) {
      selfRegistration.company = company
      useSelfRegistration().requestSelfRegistration(
        selfRegistration,
        (newStep) => (step.value = newStep)
      )
    }

    function handlePasswordSubmit(password: string) {
      selfRegistration.password = password
      useSelfRegistration().performSelfRegistration(selfRegistration)
    }

    return {
      branding,
      loading,
      title,
      step,
      toLogin,
      handleContactSubmit,
      Step,
      handleCompanySubmit,
      handlePasswordSubmit
    }
  }
})
</script>
