<template>
  <sf-alert-base v-bind="{ icon: mdiCheckCircleOutline, ...$attrs }" type="success">
    <slot />
  </sf-alert-base>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SfAlertBase from '@/components/alerts/SfAlertBase.vue'
import { mdiCheckCircleOutline } from '@mdi/js'
export default defineComponent({
  name: 'SfAlertSuccess',
  components: { SfAlertBase },
  setup() {
    return {
      mdiCheckCircleOutline
    }
  }
})
</script>
