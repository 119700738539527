<template>
  <div>
    <!-- text button -->
    <sf-button
      v-if="!isIcon"
      id="cart-button"
      class="cart-btn"
      :loading="(loading.add && loading.supplierRowId === supplierRowId) || scheduledHandleClick"
      @click="handleClick"
      >{{ isRequest ? $t('requestPrice') : $t('addToCart') }}
    </sf-button>

    <!-- compact icon button -->
    <sf-icon-button
      v-if="isIcon"
      :loading="(loading.add && loading.supplierRowId === supplierRowId) || scheduledHandleClick"
      @click.prevent="handleClick"
    >
      {{ isRequest ? mdiSend : mdiCart }}
    </sf-icon-button>

    <!-- send request dialog -->
    <v-dialog v-model="dialog" scrollable :width="800">
      <v-card>
        <v-card-item>
          <v-card-title>
            <sf-heading>{{ $t('requestPrice') }}</sf-heading>
          </v-card-title>
        </v-card-item>
        <v-card-text class="d-flex flex-column">
          <sf-text class="mb-1">
            {{ $t('requestPriceHint') }}
          </sf-text>
          <v-textarea
            v-model="previewSubject"
            :label="$t('collection.subject')"
            variant="outlined"
            hide-details
            readonly
            class="my-1"
            :rows="1"
            auto-grow
            density="compact"
            color="primary"
            autocomplete="off"
          />
          <sf-html-preview v-if="previewText" :preview-text="previewText" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <sf-text-button color="grey" class="text-medium-emphasis" @click="toggleDialog">
            {{ $t('cancel') }}
          </sf-text-button>
          <sf-text-button color="primary" :loading="sending" @click="sendRequest">
            {{ $t('send') }}
          </sf-text-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import SfButton from '@/components/button/SfButton.vue'
import SfHtmlPreview from '@/components/preview/SfHtmlPreview.vue'
import SfIconButton from '@/components/button/SfIconButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { CartItemPostDTO } from '@/generatedTypes'
import useCart from '@/modules/cart/useCart'
import { RequestEmailResponse } from '@/modules/product/types'
import useProductRequest from '@/modules/product/useProductRequest'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { defineComponent, PropType, ref, watch } from 'vue'
import { mdiSend, mdiSort, mdiCart } from '@mdi/js'

export default defineComponent({
  name: 'CartButton',
  components: {
    SfButton,
    SfHeading,
    SfIconButton,
    SfText,
    SfTextButton,
    SfHtmlPreview
  },
  props: {
    onClickEvent: {
      type: Object as PropType<AddToCartButtonEvents>,
      default: undefined
    },
    isIcon: {
      type: Boolean,
      default: false
    },
    isRequest: {
      type: Boolean,
      default: false
    },
    productId: {
      type: Number,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    },
    supplierRowId: {
      type: Number,
      default: undefined
    },
    updatingQuantity: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const dialog = ref(false)
    const previewText = ref<string | undefined>(undefined)
    const previewSubject = ref('')
    const { loading } = storeToRefs(useCart())
    const { sending } = storeToRefs(useProductRequest())
    const scheduledHandleClick = ref(false)

    const handleClick = () => {
      if (props.updatingQuantity) {
        scheduledHandleClick.value = true
        return
      }
      if (props.isRequest) {
        useProductRequest()
          .getProductRequest(props.productId, props.supplierRowId)
          .then((data) => {
            if (data) toggleDialog(data)
          })
        if (props.onClickEvent) {
          trackEvent(props.onClickEvent.request.withProduct(props.productId, props.supplierRowId))
        }
      } else {
        addToCart()
        if (props.onClickEvent) {
          trackEvent(props.onClickEvent.addToCart.withProduct(props.productId, props.supplierRowId))
        }
      }
    }

    watch(
      () => props.updatingQuantity,
      (newValue) => {
        if (!newValue && scheduledHandleClick.value) {
          scheduledHandleClick.value = false
          handleClick()
        }
      }
    )

    const toggleDialog = (preview: RequestEmailResponse = { subject: '', body: '' }) => {
      previewSubject.value = preview ? preview.subject : ''
      previewText.value = preview ? preview.body : ''
      dialog.value = !dialog.value
    }

    const addToCart = () => {
      const item: CartItemPostDTO = {
        productId: props.productId,
        quantity: props.quantity
      }
      if (props.supplierRowId) {
        item.supplierRowId = props.supplierRowId
      }
      useCart().addCartItem(item)
    }

    const sendRequest = () => {
      useProductRequest()
        .sendProductRequest(props.productId)
        .then(() => toggleDialog())
    }

    return {
      loading,
      sending,
      dialog,
      previewSubject,
      previewText,
      handleClick,
      toggleDialog,
      sendRequest,
      scheduledHandleClick,
      mdiSend,
      mdiSort,
      mdiCart
    }
  }
})
</script>
