import { defineStore } from 'pinia'

export class Message {
  timestamp: number = Date.now()
  message: string
  timeout = 5000
  color = 'white'
  count = 0
  link?: string
  showRefresh = false
  isShown: boolean = true

  constructor(
    message: string,
    count: number,
    color?: string,
    timeout?: number,
    link?: string,
    showRefresh?: boolean
  ) {
    this.message = message
    this.count = count
    if (timeout) {
      this.timeout = timeout
    }
    if (color) {
      this.color = color
    }
    this.link = link
    if (showRefresh) {
      this.showRefresh = showRefresh
    }
  }
}

const useMessaging = defineStore('messaging', {
  state: () => ({
    snackbarMessages: [] as Message[]
  }),
  actions: {
    addError(message: string, link?: string, showRefresh?: boolean) {
      this.newMessage(message, 'red', 10000, link, showRefresh)
    },
    addSuccess(message: string, link?: string) {
      this.newMessage(message, 'green', 10000, link)
    },
    addInfo(message: string, link?: string) {
      this.newMessage(message, 'white', 10000, link)
    },
    addWarning(message: string, link?: string) {
      this.newMessage(message, 'orange', 10000, link)
    },
    newMessage(
      message: string,
      color: string,
      timeout: number,
      link?: string,
      showRefresh?: boolean
    ) {
      let found = false

      for (const [key, item] of this.snackbarMessages.entries()) {
        if (item.message === message) {
          this.snackbarMessages.splice(
            key,
            1,
            new Message(message, item.count + 1, color, timeout, link, showRefresh)
          )
          found = true
          break
        }
      }

      if (!found) {
        this.snackbarMessages.push(new Message(message, 1, color, timeout, link, showRefresh))
      }
    }
  }
})

export default useMessaging
