import { ApiError, ErrorHandlingConfig, handleApiError } from '@/handleApiError'
import api from '@/modules/cart/api'
import { Cart, CartItemPostDTO, SellerComments } from '@/generatedTypes'
import useCartCount from '@/modules/cart/useCartCount'
import useCartItemMove from '@/modules/cart/useCartItemMove'
import useCartOptimizations from '@/modules/cart/useCartOptimizations'
import helpers from '@/modules/message/helpers'
import router from '@/router'
import { UPDATE_TIMESTAMP } from '@/utils/timestampUtils'
import { defineStore } from 'pinia'

interface CartState {
  cart: Cart | undefined
  loading: {
    cart: boolean
    realtime: boolean
    add: boolean
    supplierRowId: number | undefined
    remove: boolean
  }
}

const initialState = (): CartState => {
  return {
    cart: undefined,
    loading: {
      cart: false,
      realtime: false,
      add: false,
      supplierRowId: undefined,
      remove: false
    }
  }
}

const REMOVE_CART_ITEM = (state: CartState, supplierCartId: string, cartItemId: string) => {
  if (state.cart) {
    const supplierCart = state.cart.supplierCarts.find(
      (supplierCart) => supplierCart.id === supplierCartId
    )
    if (supplierCart) {
      supplierCart.cartItems.splice(
        supplierCart.cartItems.findIndex((item) => {
          return item.id === cartItemId
        }),
        1
      )

      if (supplierCart.cartItems.length === 0) {
        state.cart.supplierCarts.splice(
          state.cart.supplierCarts.findIndex((item) => {
            return item.id === supplierCartId
          }),
          1
        )
      }
    }
  }
}

const useCart = defineStore('cart', {
  state: () => initialState(),
  getters: {
    isEmpty: (state) => {
      return !state.cart || state.cart.supplierCarts.length === 0
    },
    getSupplierCart: (state) => {
      return (id: string) =>
        state.cart?.supplierCarts.find((supplierCart) => supplierCart.id === id)
    }
  },
  actions: {
    /**
     * Loads the cart.
     * @param background true if the error ought to be fullscreen, false otherwise
     * @returns
     */
    async loadCart(background: boolean) {
      this.loading.cart = !background
      useCartCount().loadCartCount()
      useCartItemMove().loadCartItemMoveInfo()

      return api
        .loadCart()
        .then(({ data }) => {
          this.cart = data
          this.loadCartUpdate()
        })
        .catch((error: ApiError) => {
          const configParam: Partial<ErrorHandlingConfig> = {
            customMessageKey: 'error.cartNotFound'
          }
          if (!background) {
            configParam.appearance = 'FULLPAGE'
          }
          handleApiError(error, configParam)
        })
        .finally(() => {
          this.loading.cart = false
        })
    },
    async loadCartUpdate() {
      this.loading.realtime = true
      return api
        .loadCart(true)
        .then(({ data }) => {
          this.cart = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading.realtime = false
        })
    },
    async changeVatCountry(iso3country: string) {
      this.loading.realtime = true
      return api
        .loadCart(true, iso3country)
        .then(({ data }) => {
          this.cart = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading.realtime = false
        })
    },
    async addCartItem(item: CartItemPostDTO) {
      this.loading.add = true
      this.loading.supplierRowId = item.supplierRowId ? item.supplierRowId : undefined

      return api
        .addCartItem(item)
        .then(() => {
          helpers.reportSuccess('success.productAdded')
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.productAdded'
          })
        })
        .finally(() => {
          this.loading.add = false
          this.loading.supplierRowId = undefined
          useCartCount().loadCartCount()
        })
    },
    async removeCartItem(supplierCartId: string, cartItemId: string) {
      this.loading.remove = true
      REMOVE_CART_ITEM(this, supplierCartId, cartItemId)

      return api
        .removeCartItem(cartItemId)
        .then(() => {
          helpers.reportSuccess('success.productDeleted')
          useCartOptimizations().calculateOptimizations()
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              customMessageKey: 'error.productDeleted'
            },
            { errorCode: 404, logError: false, appearance: 'NONE' }
          )
        })
        .finally(() => {
          this.loading.remove = false
          this.loadCart(true)
        })
    },
    async changeQuantity(payload: { id: string; quantity: number }) {
      const currentTimestamp = this.cart?.timestamp || ''
      return api
        .patchCartItem(payload.id, {
          quantity: payload.quantity,
          timestamp: currentTimestamp
        })
        .then(({ data }) => {
          useCartOptimizations().calculateOptimizations()
          UPDATE_TIMESTAMP(this.$state.cart, data)
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              customMessageKey: 'error.changeQuantity'
            },
            { errorCode: 404, logError: false, appearance: 'NONE' }
          )
        })
        .finally(() => {
          this.loadCart(true)
        })
    },
    async updateSupplierComment(
      cartItemId: string,
      supplierComment: string,
      timestamp: string,
      updateCheckoutCartFn?: (supplierCartId?: string) => void,
      supplierCartId?: string
    ) {
      return api
        .patchCartItem(cartItemId, {
          supplierComment,
          timestamp
        })
        .then(({ data }) => {
          UPDATE_TIMESTAMP(this.$state.cart, data)
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              customMessageKey: 'error.updateComment'
            },
            { errorCode: 404, logError: false, appearance: 'NONE' }
          )
        })
        .finally(() => {
          this.loadCart(true)
          if (updateCheckoutCartFn) {
            updateCheckoutCartFn(supplierCartId)
          }
        })
    },
    async updateSellerComment(
      cartItemId: string,
      comment: string,
      fieldIndex: keyof SellerComments,
      timestamp: string,
      updateCheckoutCartFn?: (supplierCartId?: string) => void,
      supplierCartId?: string
    ) {
      return api
        .patchCartItem(cartItemId, {
          sellerComments: { [fieldIndex]: comment },
          timestamp
        })
        .then(({ data }) => {
          UPDATE_TIMESTAMP(this.$state.cart, data)
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              customMessageKey: 'error.updateComment'
            },
            { errorCode: 404, logError: false, appearance: 'NONE' }
          )
        })
        .finally(() => {
          this.loadCart(true)
          if (updateCheckoutCartFn) {
            updateCheckoutCartFn(supplierCartId)
          }
        })
    },
    async emptyCart() {
      this.cart = undefined

      return api
        .emptyCart()
        .then(() => helpers.reportSuccess('cart.removeAllSuccess'))
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'cart.removeAllError'
          })
        })
        .finally(() => {
          this.loadCart(true)
        })
    },
    async requestCart() {
      return api
        .createQuoteRequest()
        .then(({ data }) => {
          helpers.reportSuccess('quotes.quoteRequested')
          router.push({ name: 'Quote', params: { id: data } })
        })
        .catch(handleApiError)
    }
  }
})

export default useCart

export { CartState }
