<template>
  <v-form ref="formRef" v-model="valid" lazy-validation>
    <v-row dense>
      <v-col cols="12">
        <sf-select
          ref="title"
          v-model="contactForm.selectedTitle"
          :items="titleItems"
          :label="$t('user.contactTitle')"
          hide-details="auto"
          item-title="translation"
          return-object
          autocomplete="honorific-prefix"
        />
      </v-col>
      <v-col cols="6">
        <sf-text-field
          ref="firstName"
          v-model="contactForm.firstName"
          :label="$t('user.firstName')"
          :rules="[rules.requiredRule, rules.nameRule]"
          hide-details="auto"
          autocomplete="given-name"
        />
      </v-col>
      <v-col cols="6">
        <sf-text-field
          ref="lastName"
          v-model="contactForm.lastName"
          :label="$t('user.lastName')"
          :rules="[rules.requiredRule, rules.nameRule]"
          hide-details="auto"
          autocomplete="family-name"
        />
      </v-col>
      <v-col cols="12">
        <sf-text-field
          ref="mail"
          v-model="contactForm.email"
          :label="$t('user.email')"
          :rules="[rules.mailRule, rules.requiredRule]"
          :error-messages="customErrorMessage"
          autocomplete="email"
          @input="customErrorMessage = ''"
        />
      </v-col>
    </v-row>

    <v-checkbox
      ref="terms"
      v-model="consentForm.consent"
      color="primary"
      :label="$t('privacyConsent')"
      :rules="[rules.requiredRule]"
      hide-details
    />
    <custom-terms-checkbox
      v-if="customTerms.isCustomTerms"
      ref="customTermsCheckbox"
      v-model="consentForm.customTermConsent"
      :custom-terms="customTerms.terms ?? ''"
    />
    <sf-large-button :disabled="!valid" block class="mt-2" @click="submit">
      {{ $t('continue') }}
    </sf-large-button>
  </v-form>
</template>

<script lang="ts">
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfSelect from '@/components/input/SfSelect.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import { validateForm } from '@/helpers'
import CustomTermsCheckbox from '@/modules/authentication/components/CustomTermsCheckbox.vue'
import { Contact, Title } from '@/generatedTypes'
import { Step } from '@/modules/authentication/types'
import useSelfRegistration from '@/modules/authentication/useSelfRegistration'
import { getContactTitles } from '@/modules/user/helpers'
import { EmailStatus } from '@/modules/user/types'
import rules from '@/rules'
import { VForm } from '@/types'
import { storeToRefs } from 'pinia'
import { defineComponent, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'SelfRegistrationForm',
  components: { SfLargeButton, SfSelect, SfTextField, CustomTermsCheckbox },
  emits: ['submit'],
  setup(_, { emit }) {
    const { t, locale: i18nLocale } = useI18n()

    onBeforeMount(() => {
      const locale = i18nLocale.value ? i18nLocale.value : navigator.language
      useSelfRegistration().getCustomTerms(locale)
    })

    const titleItems = getContactTitles()
    const extendedForm = ref(false)
    const valid = ref(false)
    const contactForm = ref({
      firstName: '',
      lastName: '',
      email: '',
      selectedTitle: titleItems[3]
    })
    const consentForm = ref({
      consent: false,
      customTermConsent: false
    })
    const customErrorMessage = ref('')
    const { customTerms } = storeToRefs(useSelfRegistration())
    const formRef = ref<VForm>()

    async function submit() {
      if (await !validateForm(formRef)) return
      validateEmail(contactForm.value.email).then(
        (step: Step) => {
          const { selectedTitle, ...incompleteContact } = contactForm.value
          const contact: Contact = {
            ...incompleteContact,
            contactTitle: titleItems[selectedTitle.value].title as Title,
            alpha2language: i18nLocale.value
          }
          emit('submit', { contact, consent: consentForm.value, step })
        },
        (message) => {
          customErrorMessage.value = message
          valid.value = false
        }
      )
    }

    async function validateEmail(email: string) {
      const emailStatus = await useSelfRegistration().validateEmail(email)
      if (emailStatus === EmailStatus.BLACKLISTED) {
        return Promise.resolve(Step.COMPANY)
      } else if (emailStatus === EmailStatus.REGISTERED) {
        return Promise.reject(t('error.emailAlreadyRegistered'))
      } else if (emailStatus === EmailStatus.VALID) {
        return Promise.resolve(Step.PASSWORD)
      } else {
        return Promise.reject(t('error.invalidEmail'))
      }
    }

    return {
      titleItems,
      extendedForm,
      valid,
      contactForm,
      rules,
      customTerms,
      formRef,
      submit,
      consentForm,
      customErrorMessage
    }
  }
})
</script>
