<template>
  <div>
    <sf-alert-error v-if="tokenError" class="mb-4" type="error">
      {{ $t('error.registrationTokenError') }}
    </sf-alert-error>
    <v-form v-if="!tokenError" ref="form" v-model="valid" lazy-validation>
      <sf-select
        v-model="selectedTitle"
        :items="titleItems"
        item-title="translation"
        :label="$t('user.contactTitle')"
        return-object
        autocomplete="honorific-prefix"
      />
      <sf-text-field
        v-model="firstName"
        :label="$t('user.firstName')"
        :rules="[rules.nameRule]"
        required
        autocomplete="given-name"
      />
      <sf-text-field
        v-model="lastName"
        :label="$t('user.lastName')"
        :rules="[rules.nameRule]"
        class="mb-3"
        required
        autocomplete="family-name"
      />
      <sf-password-field
        v-model="password"
        :label="$t('password')"
        :rules="[rules.passwordLengthRule, rules.passwordRule]"
        required
        autocomplete="new-password"
      />
      <sf-password-field
        v-model="repeat"
        :label="$t('passwordRepeat')"
        :rules="[rules.passwordMatchRule(password)]"
        required
        autocomplete="new-password"
      />
      <v-checkbox
        ref="terms-checkbox"
        v-model="termsConsent"
        color="primary"
        :label="$t('privacyConsent')"
        :rules="[rules.requiredRule]"
        required
        hide-details
      />
      <custom-terms-checkbox
        v-if="customTerms.isCustomTerms"
        ref="custom-terms-checkbox"
        v-model="customTermsConsent"
        :custom-terms="customTerms.terms ?? ''"
      />
      <sf-large-button
        class="mt-2"
        block
        :loading="sending"
        :disabled="!valid"
        @click="sendRegistration"
      >
        {{ $t('finishRegistration') }}
      </sf-large-button>
    </v-form>
    <div class="text-center mt-2 text-body-2 text-primary">
      <div class="d-inline cursor-pointer" @click="toLogin">
        {{ $t('login') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SfAlertError from '@/components/alerts/SfAlertError.vue'
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfPasswordField from '@/components/input/SfPasswordField.vue'
import SfSelect from '@/components/input/SfSelect.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import { InvitationRegistration, Title, ValidTokenDTO } from '@/generatedTypes'
import { validateForm } from '@/helpers'
import CustomTermsCheckbox from '@/modules/authentication/components/CustomTermsCheckbox.vue'
import useSelfRegistration from '@/modules/authentication/useSelfRegistration'
import { getContactTitles } from '@/modules/user/helpers'
import router from '@/router'
import rules from '@/rules'
import { VForm } from '@/types'
import { storeToRefs } from 'pinia'
import { defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'RegistrationForm',
  components: {
    SfLargeButton,
    SfAlertError,
    SfTextField,
    SfSelect,
    CustomTermsCheckbox,
    SfPasswordField
  },
  props: {
    sending: {
      type: Boolean,
      required: true
    },
    tokenError: {
      type: Boolean,
      required: true
    },
    registrationData: {
      type: Object as PropType<ValidTokenDTO>,
      default: undefined
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  emits: ['finishRegistration'],
  setup(props, { emit }) {
    let locale = useI18n().locale.value
    onBeforeMount(() => {
      locale = useI18n().locale ? useI18n().locale.value : navigator.language
      useSelfRegistration().getCustomTerms(locale)
    })

    const titleItems = getContactTitles()
    const defaultTitle = titleItems.find((titleItem) => titleItem.title === Title.Unspecified)!
    const form = ref<VForm>()
    const valid = ref(false)

    const selectedTitle = ref(defaultTitle)
    const firstName = ref('')
    const lastName = ref('')
    const password = ref('')
    const repeat = ref('')
    const termsConsent = ref(false)
    const customTermsConsent = ref(false)

    const { customTerms } = storeToRefs(useSelfRegistration())

    watch(
      () => props.loading,
      () => {
        selectedTitle.value =
          titleItems.find((item) => item.title == props.registrationData?.contactTitle) ||
          defaultTitle
        firstName.value = props.registrationData?.firstName || ''
        lastName.value = props.registrationData?.lastName || ''
      }
    )

    const sendRegistration = async () => {
      const data: InvitationRegistration = {
        token: {
          value: ''
        },
        firstName: firstName.value,
        lastName: lastName.value,
        password: password.value,
        contactTitle: titleItems[selectedTitle.value.value].title as Title,
        termsConfirmation: termsConsent.value,
        customTermsConfirmation: customTermsConsent.value,
        alpha2language: locale
      }
      if (await validateForm(form)) {
        emit('finishRegistration', data)
      }
    }

    const toLogin = () => {
      router.replace('/login')
    }

    return {
      termsConsent,
      customTermsConsent,
      customTerms,
      firstName,
      form,
      lastName,
      password,
      repeat,
      rules,
      selectedTitle,
      titleItems,
      valid,
      sendRegistration,
      toLogin
    }
  }
})
</script>
