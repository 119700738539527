<template>
  <v-card
    elevation="1"
    class="py-1 px-2 mb-1 border-sm border-opacity-0"
    :class="[{ 'border--primary': isSelected }]"
  >
    <v-row no-gutters style="min-height: 96px">
      <!-- Image -->
      <v-col cols="auto" class="d-flex align-center mr-2">
        <product-image :url="product.imageUrl" :size="60" />
      </v-col>

      <!-- Title -->
      <v-col cols="" class="d-flex flex-column justify-center mr-3">
        <sf-text ref="product-title" class="text-truncate">
          {{ product.title }}
        </sf-text>
        <div class="d-flex align-center my-half">
          <sf-non-retail-chip
            v-if="product.condition && product.condition !== Condition.NEW"
            class="mr-1"
          />
          <product-identifiers
            :article-number="product.articleNumber"
            :product-id="product.productId"
          />
        </div>
        <product-short-description
          class="text-body-2 two-lines"
          :description="product.description"
        />
      </v-col>

      <!-- Price -->
      <v-col
        ref="product-price"
        cols="auto"
        class="d-flex flex-column justify-center align-end text-primary mr-2"
      >
        <template v-if="product.price">
          <span class="text-h5">
            {{ formatMoney(product.price.resellPrice, true) }}
          </span>
          <billing-period
            v-if="product.price.subscriptionModel"
            class="text-body-1"
            :subscription-model="product.price.subscriptionModel"
          />
          <packaging-info
            v-if="product.price.packagingModel"
            class="text-body-1"
            :packaging-model="product.price.packagingModel"
          />
        </template>
      </v-col>

      <!-- Stock -->
      <v-col cols="auto" class="d-flex justify-end align-center">
        <v-icon
          v-if="product.stock"
          size="medium"
          :color="getStatusColor(product.stock.status)"
          :icon="mdiCircle"
        />
        <v-icon v-else size="large" color="grey" :icon="mdiCircle" />
      </v-col>
      <v-col v-if="showAddToCart" cols="auto" class="d-flex align-center ml-2">
        <cart-button
          v-if="product.price"
          :is-icon="true"
          :is-request="product.price.resellPrice.value <= 0"
          :product-id="product.productId"
          :quantity="1"
          :supplier-row-id="product.price.supplierRowId"
          :on-click-event="addToCartEvent"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import CartButton from '@/components/button/CartButton.vue'
import SfNonRetailChip from '@/components/chips/SfNonRetailChip.vue'
import PackagingInfo from '@/components/PackagingInfo.vue'
import ProductImage from '@/components/ProductImage.vue'
import SfText from '@/components/text/SfText.vue'
import ProductIdentifiers from '@/modules/product/components/ProductIdentifiers.vue'
import ProductShortDescription from '@/modules/product/components/ProductShortDescription.vue'
import { formatMoney, getStatusColor } from '@/modules/product/helpers'
import { Condition, MediumProduct } from '@/generatedTypes'
import ProductSearchEvent from '@/modules/tracking/events/search'
import { mdiCircle } from '@mdi/js'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ProductRow',
  components: {
    BillingPeriod,
    CartButton,
    PackagingInfo,
    ProductIdentifiers,
    ProductImage,
    ProductShortDescription,
    SfNonRetailChip,
    SfText
  },
  props: {
    product: {
      type: Object as PropType<MediumProduct>,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    showAddToCart: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      addToCartEvent: ProductSearchEvent.AddToCart.List,
      getStatusColor,
      formatMoney,
      Condition,
      mdiCircle
    }
  }
})
</script>
